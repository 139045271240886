.content.centrale div.properties div.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}
.content.centrale div.properties div.card-container .card {
    position: relative;
    display: flex;
    width: 240px;
    height: 320px;
    border-radius: 8px;
    box-sizing: border-box;
    perspective: 1000px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 24px;
    cursor: pointer;
}
.content.centrale div.properties div.card-container .card .text-content {
    z-index: 60;
    position: relative;
    color: #fff;
    margin: auto;
}
.content.centrale div.properties div.card-container .card .text-content h4 {
    font-size: 42px;
    font-family: "Lato";
    font-weight: normal;
    font-style: italic;
    text-align: center;
    margin: 0;
}
.content.centrale div.properties div.card-container .card:nth-child(1) .text-content h4 {
    font-family: 'Omega Sans';
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1em;
    text-transform: uppercase;
    font-style: normal;
}
.content.centrale div.properties div.card-container .card .text-content span {
    display: block;
    font-family: 'Lato';
    font-weight: 300;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 8px;
}
.content.centrale div.properties div.card-container .card:nth-child(2) .text-content span {
    margin-top: 4px;
    max-width: unset;
}
.content.centrale div.properties div.card-container .card:nth-child(3) .text-content span {
    margin-top: 0;
    max-width: unset;
}
.content.centrale div.properties div.card-container .card:nth-child(2) .text-content h4 {
    font-family: 'Ethereal';
    letter-spacing: 1px;
    text-transform: uppercase;
    font-style: normal;
}
.content.centrale div.properties div.card-container .card:nth-child(3) .text-content h4 {
    font-size: 72px;
    font-family: 'Authentic';
    font-style: normal;
}
.content.centrale div.properties div.card-container .card .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}
.content.centrale div.properties div.card-container .card.show .card-inner {
    transform: rotateY(180deg);
}
/* Position the front and back side */
.content.centrale div.properties div.card-container .card .card-inner .card-front, .content.centrale div.properties div.card-container .card .card-inner .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 4px #fff;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 12px;
  box-sizing: content-box;
  overflow: hidden;
  box-shadow: 4px 8px 12px #40404171;
}
/* Style the front side (fallback if image is missing) */
.content.centrale div.properties div.card-container .card .card-inner .card-front {
    display: flex;
    background-color: #bbb;
}
.content.centrale div.properties div.card-container .card .card-inner .card-front .bg-overlay,
.content.centrale div.properties div.card-container .card .card-inner .card-back .bg-overlay {
    z-index: 40;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 400ms ease-in-out;
}
.content.centrale div.properties div.card-container .card .card-inner .card-front:hover .bg-overlay {
    transform: scale(1.25);
}
/* Style the back side */
.content.centrale div.properties div.card-container .card .card-inner .card-back {
    z-index: 1000;
    display: flex;
    color: white;
    transform: rotateY(180deg);
}
.content.centrale div.properties div.card-container .card .card-inner .card-back .text-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.content.centrale div.properties div.card-container .card .card-inner .card-back .text-content h5 {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 0;
    margin-top: auto;
    margin-bottom: 8px;
}
.content.centrale div.properties div.card-container .card .card-inner .card-back .text-content p {
    text-align: center;
    margin: 0;
    margin-bottom: 2px;
}
.content.centrale div.properties div.card-container .card:nth-child(1) .card-inner .card-back .text-content div {
    z-index: 41;
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;
    box-sizing: border-box;
}
.content.centrale div.properties div.card-container .card .card-inner .card-back .text-content a {
    z-index: 1000;
    color: #fff;
    font-family: 'Lato';
    text-decoration: none;
    text-align: center;
    margin: auto;
    padding: 6px;
    padding-bottom: 8px;
    min-width: 120px;
    border: solid 2px #fff;
    border-radius: 42px;
    box-sizing: border-box;
    background-color: #4e2e61;
    margin-top: 4px;
}
.content.centrale div.properties div.card-container .card:nth-child(1) .card-inner .card-back .text-content a {
    margin-top: 4px;
}
.content.centrale div.properties div.card-container .card .color-overlay {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(25deg, #522d6d, #4197cb);
    opacity: .9;
}
.content.centrale div.properties div.card-container .card:nth-child(1) .color-overlay {
    background-image: linear-gradient(25deg, #4e2e61, #eab03b);
}
.content.centrale div.properties div.card-container .card:nth-child(2) .color-overlay {
    background-image: linear-gradient(25deg, #b0b588, #c6ae6e);
}
.content.centrale div.properties div.card-container .card:nth-child(3) .color-overlay {
    background-image: linear-gradient(25deg, #314c55 30%, #829c6e);
}
@media (min-width: 568px) {
    .content.centrale div.properties div.card-container .card {
        margin: 16px 12px;
    }
}
@media (min-width: 768px) {
}
@media (min-width: 1280px) {
    .content.centrale div.properties div.card-container {
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 16px;
        margin-bottom: 24px;
    }
    .content.centrale div.properties div.card-container .card {
        margin: 16px;
    }
    
    .content.centrale div.properties div.card-container .card .card-inner .card-back .text-content a {
    }
    .content.centrale div.properties div.card-container .card:nth-child(1) .card-inner .card-back .text-content a {
        margin-bottom: auto;
    }
}