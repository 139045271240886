.content.sights div.banner {
  position: relative;
  display: flex;
  width: 100%;

  min-height: 420px;
  box-sizing: border-box;

  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.content.sights div.banner .color-overlay {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, .75); */
  /* background-image: linear-gradient(0deg, rgba(200,200,200,.7) 48%, transparent); */
  background-image: linear-gradient(
    0deg,
    rgba(250, 250, 250, 0.85) 48%,
    transparent
  );
}
.content.sights div.banner .text-content {
  z-index: 300;
  color: #fff;
  width: 100%;
  max-width: 90%;
  margin: auto;
  text-align: center;
}
.content.sights div.banner .text-content h1 {
  /* font-family: 'Montserrat'; */
  font-family: "Lato";
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
}
.content.sights div.banner .text-content h1 span {
  font-family: "Lato";
  font-weight: 400;
  font-size: 1.2em;
}
.content.sights div.banner .text-content p {
  /* font-size: 16px; */
  font-size: 14px;
  /* text-align-last: left; */
  line-height: 1.25em;
  margin: 0;

  color: #404041;
}

.content.sights div.video-embed {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 42px 0 0 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.content.sights div.video-embed iframe {
  aspect-ratio: 16/9;
  height: 100%;
  width: 100%;
  max-width: 800px;

  margin: auto;
}

.header-img-sights {
  /* width: 250px;
    margin-left: -20px; */
  /* width: 250px; */
  width: 253px;
  /* width: 450px; */
  /* width: 50% !important; */
  /* margin-left: -20px; */
  /* text-align: center; */
  /* margin-bottom: 20px; */
  /* margin-left: auto; */
  margin-left: 12px;
  margin-right: auto;
  /* margin-top: 120px; */
}

@media only screen and (max-width: 600px) {
  .header-img-sights {
    margin-top: 80px;
  }
}

@media (min-width: 1023px) {
  .content.sights div.banner {
    /* min-height: 480px; */
    min-height: 500px;
  }
  .content.sights div.banner .text-content {
    max-width: 840px;
  }
  .content.sights div.banner .text-content h1 {
    font-size: 42px;
  }
  .content.sights div.banner .text-content p {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .content.sights div.banner {
    background-position: 100% 130%;
    background-attachment: fixed;
  }
}
@media (min-width: 1600px) {
  .content.sights div.banner {
    min-height: 600px;
  }
  .content.sights div.banner .text-content p {
    font-size: 20px;
  }
  .content.sights div.banner .text-content h1 {
    font-size: 54px;
  }
}
@media (min-width: 1900px) {
  .content.sights div.banner .text-content h1 {
    font-size: 60px;
  }
}
.sightsBtn {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #e5a724;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  max-width: 17rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin: 32px auto;
}

.sights__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: #e5a724;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.sightsBtn:hover {
  background-color: white;
  color: #e5a724;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.sights__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.sightsBtn:hover .sights__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.sightsBtn:hover .sights__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

.sightsPillar {
  position: relative;
  height: 95vh;
  max-height: 1080px;
  background-image: linear-gradient(
      to bottom,
      white,
      rgba(255, 255, 255, 0) 50%
    ),
    url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/pillar/sight.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.sightsPillar-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 82px 16px;
  z-index: 2;
}
.sightsPillar-content p {
  font-family: "Poppins" !important;
  font-size: 1.5rem;
  line-height: 0%;
  color: black;
  margin-top: 16px;
}
.sightsWrapper-pillar {
  position: relative;
}

.sightsWrapper-pillar .sightsMain-text,
.sightsAnimation-pillar {
  font-family: "Poppins" !important;
  font-size: 3rem;
  font-weight: 600;
  line-height: 0%;
  color: #e5a724;
}
.sightsWrapper-pillar .sightsMain-text {
  opacity: 0;
  padding: 0 6px;
}
.sightsAnimation-pillar {
  position: absolute;
  top: -1.8rem;
  height: auto;
  overflow: hidden;
  line-height: normal;
  width: auto;
  text-align: start;
  margin: 0 6px;
}
@media (max-width: 1024px) {
  .sightsPillar {
    height: 70vh;
  }
  .sightsPillar-content p {
    font-size: 1rem;
  }
  .sightsWrapper-pillar .sightsMain-text,
  .sightsAnimation-pillar {
    font-family: "Poppins" !important;
    font-size: 2rem;
    line-height: 0%;
    color: #e5a724;
  }
  .sightsAnimation-pillar {
    position: absolute;
    top: -1.2rem;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
    margin: 0 6px;
  }
}
@media (max-width: 768px) {
  .sightsPillar {
    height: 60vh;
  }
  .sightsPillar-content {
    padding: 68px 16px;
  }
  .sightsPillar-content p {
    font-size: 1rem;
    margin-top: 12px;
  }
  .sightsWrapper-pillar .sightsMain-text,
  .sightsAnimation-pillar {
    font-family: "Poppins" !important;
    font-size: 1.7rem;
    line-height: 0%;
    color: #e5a724;
  }
  .sightsAnimation-pillar {
    position: absolute;
    top: -0.8rem;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
  }
}
@media (max-width: 640px) {
  .sightsPillar {
    height: 40vh;
  }
  .sightsPillar-content {
    padding: 32px 0;
  }
  .sightsPillar-content p {
    font-size: 12px;
    margin-top: 4px;
  }
  .sightsWrapper-pillar .sightsMain-text,
  .sightsAnimation-pillar {
    font-family: "Poppins" !important;
    font-size: 15px;
    line-height: 0%;
    color: #e5a724;
  }
  .sightsWrapper-pillar .sightsMain-text {
    opacity: 0;
    padding: 0 2px;
  }
  .sightsAnimation-pillar {
    position: absolute;
    top: -5px;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
    margin: 0 2px;
  }
}
@media (max-width: 320px) {
  .sightsPillar {
    height: 35vh;
  }

  .sightsPillar-content p {
    font-size: 9px;
    margin-top: 4px;
  }
  .sightsWrapper-pillar .sightsMain-text,
  .sightsAnimation-pillar {
    font-family: "Poppins" !important;
    font-size: 14px;
    line-height: 0%;
    color: #e5a724;
  }
  .sightsAnimation-pillar {
    position: absolute;
    top: -6px;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
  }
}
