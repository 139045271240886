.content.career-list .career-post {
	position: relative;
	display: flex;
	width: 100%;
	min-height: 480px;

	background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/careers-bg.jpg');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}
.content.career-list .career-post {
	position: relative;
	width: 100%;
	padding: 60px 24px;
	box-sizing: border-box;
}
.content.career-list .career-post .list {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 940px;
	margin: 0 auto;
}

.content.career-list .career-card-rect {
	position: relative;
	display: flex;
	align-items: stretch;

	width: 100%;
	min-height: 320px;
	max-height: 360px;

	box-sizing: border-box;
	border-radius: 4px;
	overflow: hidden;

	background-color: #fff;
	margin-bottom: 60px;

	box-shadow: 0 4px 12px rgba(0, 0, 0, .32);
}
.content.career-list .career-card-rect .career-content {
	position: relative;
	display: flex;
	align-items: stretch;
	height: inherit;
	width: 100%;
}

.content.career-list .career-card-rect .left-section {
	position: relative;
	width: 100%;
	max-width: 320px;
}
.content.career-list .career-card-rect .left-section .background-overlay {
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-image: linear-gradient(25deg, #522D6D, #4197CB);
}
.content.career-list .career-card-rect .left-section .text-content {
	z-index: 120;
	position: relative;
	display: flex;
	flex-direction: column;

	height: 100%;

	color: #fff;

	padding: 24px;
	box-sizing: border-box;
}
.content.career-list .career-card-rect .left-section .text-content h4 {
	font-size: 24px;
	margin: 0;
	margin-bottom: 16px;
}
.content.career-list .career-card-rect .left-section .text-content p {
	margin: 0;
}
.content.career-list .career-card-rect .left-section .text-content a {
	display: inline-block;
	/* font-family: 'Montserrat'; */
	font-family: "Lato";
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;

	width: 100%;

	padding: 6px;
	border: solid 2px #fff;
	border-radius: 42px;
	box-sizing: border-box;

	background-color: #379144;
	color: #fff;
	margin: 0 auto;
	margin-top: auto;
}
.content.career-list .career-card-rect .left-section .text-content div.job-item {
	display: flex;
	align-items: flex-start;
}
.content.career-list .career-card-rect .left-section .text-content div.job-item p {
	font-size: 15px;
	margin-bottom: 6px;
}
.content.career-list .career-card-rect .left-section .text-content div.job-item p:nth-child(1) {
	text-align: left;
	width: 120px;
}
.content.career-list .career-card-rect .left-section .text-content div.job-item p:nth-child(2) {
	margin-left: 20px;
}

.content.career-list .career-card-rect .right-section .text-content {
	position: relative;
	padding: 24px;
	box-sizing: border-box;
}
.content.career-list .career-card-rect .right-section .text-content h4 {
	/* font-family: 'Montserrat'; */
	font-family: "Lato";
	font-weight: 600;
	margin: 0;
	margin-bottom: 8px;
}
.content.career-list .career-card-rect .right-section .text-content p {
	font-weight: 400;
	margin: 0;
}
.content.career-list .career-card-rect .right-section .text-content ul {
	padding-inline-start: 28px;
}
.content.career-list .career-card-rect .right-section .text-content ul li {
	margin-bottom: 6px;
}
.content.career-list .career-card-rect .right-section div.bottom-blur {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 54px;

	background-image: linear-gradient(0deg, #fff 32%, transparent);
}