.content.impressions div.banner {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 420px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.content.impressions div.banner .color-overlay {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(250, 250, 250, 0.85) 48%,
    transparent
  );
}
.content.impressions div.banner .text-content {
  z-index: 300;
  color: #fff;
  width: 100%;
  max-width: 85%;
  margin: auto;
  text-align: center;
}
.content.impressions div.banner .text-content h1 {
  font-family: "Lato";
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  margin-bottom: 8px;
}
.content.impressions div.banner .text-content h1 span {
  font-family: "Etherial";
  font-weight: 400;
  font-size: 1.2em;
}
.content.impressions div.banner .text-content p {
  font-size: 14px;
  line-height: 1.25em;
  margin: 0;
  color: #404041;
}

.header-img-impressions {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .header-img-impressions {
    margin-top: 10px;
  }
}
@media (min-width: 375px) {
  .content.impressions div.banner .text-content p {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .content.impressions div.banner .text-content h1 {
    font-size: 36px;
  }
  .content.impressions div.banner .text-content {
    margin-bottom: 80px;
  }
}
@media (min-width: 1023px) {
  .content.impressions div.banner {
    min-height: 500px;
  }
  .content.impressions div.banner .text-content {
    max-width: 840px;
  }
  .content.impressions div.banner .text-content h1 {
    font-size: 42px;
  }
  .content.impressions div.banner .text-content p {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .content.impressions div.banner .text-content h1 {
    font-size: 42px;
  }
  .content.impressions div.banner .text-content p {
    font-size: 18px;
  }
  .content.impressions div.banner {
    background-attachment: fixed;
    background-position: 100% 100%;
  }
}
@media (min-width: 1440px) {
  .content.impressions div.banner .text-content {
    margin-bottom: 127px;
  }
  .content.impressions div.banner .color-overlay {
    background-image: linear-gradient(
      0deg,
      rgba(250, 250, 250, 0.7) 48%,
      transparent
    );
  }
}
@media (min-width: 1600px) {
  .content.impressions div.banner {
    min-height: 600px;
  }
  .content.impressions div.banner .text-content {
    margin-bottom: 120px;
  }
  .content.impressions div.banner .text-content h1 {
    font-size: 54px;
  }
}
@media (min-width: 1900px) {
  .content.impressions div.banner .text-content {
    padding: 24px 200px;
    max-width: 1000px;
  }

  .content.impressions div.banner .text-content h1 {
    font-size: 60px;
  }
}
.video-embed {
  width: 100%;
  display: flex;
  justify-content: center;
}
.impresionBtn {
  position: relative;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #4e2e61;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  max-width: 17rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin: 32px auto;
  z-index: 20;
}

.impresion__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: #4e2e61;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.impresionBtn:hover {
  background-color: white;
  color: #4e2e61;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.impresion__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.impresionBtn:hover .impresion__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.impresionBtn:hover .impresion__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

.impresionPillar {
  position: relative;
  height: 95vh;
  max-height: 1080px;
  background-image: linear-gradient(
      to bottom,
      white,
      rgba(255, 255, 255, 0) 85%
    ),
    url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/pillar/impresion.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.impresionPillar-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 82px 16px;
  z-index: 2;
}
.impresionPillar-content p {
  font-family: "Didot" !important;
  font-size: 1.5rem;
  line-height: 0%;
  color: black;
  margin-top: 16px;
}
.impresionWrapper-pillar {
  position: relative;
}

.impresionWrapper-pillar .impresionMain-text,
.impresionAnimation-pillar {
  font-family: "Didot" !important;
  font-size: 3rem;
  line-height: 0%;
  color: #4e2e61;
}
.impresionWrapper-pillar .impresionMain-text {
  opacity: 0;
  padding: 0 6px;
}
.impresionAnimation-pillar {
  position: absolute;
  top: -1.1rem;
  height: auto;
  overflow: hidden;
  line-height: normal;
  width: auto;
  text-align: start;
  margin: 0 6px;
}
@media (max-width: 1024px) {
  .impresionPillar {
    height: 70vh;
  }
  .impresionPillar-content p {
    font-size: 1rem;
  }
  .impresionWrapper-pillar .impresionMain-text,
  .impresionAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 2rem;
    line-height: 0%;
    color: #4e2e61;
  }
  .impresionAnimation-pillar {
    position: absolute;
    top: -0.7rem;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
    margin: 0 6px;
  }
}
@media (max-width: 768px) {
  .impresionPillar-content {
    padding: 68px 16px;
  }
  .impresionPillar-content p {
    font-size: 1rem;
    margin-top: 12px;
  }
  .impresionWrapper-pillar .impresionMain-text,
  .impresionAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 1.7rem;
    line-height: 0%;
    color: #4e2e61;
  }
  .impresionAnimation-pillar {
    position: absolute;
    top: -0.4rem;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
  }
}
@media (max-width: 640px) {
  .impresionPillar {
    height: 40vh;
  }
  .impresionPillar-content {
    padding: 32px 0;
  }
  .impresionPillar-content p {
    font-size: 12px;
    margin-top: 4px;
  }
  .impresionWrapper-pillar .impresionMain-text,
  .impresionAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 15px;
    line-height: 0%;
    color: #4e2e61;
  }
  .impresionWrapper-pillar .impresionMain-text {
    opacity: 0;
    padding: 0 2px;
  }
  .impresionAnimation-pillar {
    position: absolute;
    top: -1px;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
    margin: 0 2px;
  }
}
@media (max-width: 320px) {
  .impresionPillar {
    height: 35vh;
  }

  .impresionPillar-content p {
    font-size: 9px;
    margin-top: 4px;
  }
  .impresionWrapper-pillar .impresionMain-text,
  .impresionAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 14px;
    line-height: 0%;
    color: #4e2e61;
  }
  .impresionAnimation-pillar {
    position: absolute;
    top: -3px;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
  }
}
