footer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 100%;


    background-color: #404041;
    box-sizing: border-box;
}
footer div.section-cont {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 32px;
    box-sizing: border-box;
}

footer img.phirst-logo {
    max-width: 200px;
}

footer * {
    color: #fff;
}



footer div.section-cont > div {
    margin: 0;
}
footer div.section-cont > div:last-child {
    margin-right: 0;

    margin-left: 90px;
}
footer div.section-cont div.footer-section1 {
    max-width: 480px;
}
footer div.footer-section1 p {
    text-align: justify;
    line-height: 1.25em;
}


footer div.section-cont > div > div {
    margin-bottom: 24px;
}
footer div.section-cont > div > div h4 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 16px;
}
footer div.section-cont > div ul {
    margin: 0;
    list-style-type: none;
    padding-inline-start: 0;
}
footer div.section-cont > div ul li {
    margin-bottom: 4px;
}
footer.footer-v2 div.section-cont > div ul li {
    margin-bottom: 12px;
}
footer div.section-cont > div ul li a {
    text-decoration: none;
}


footer div.footer-section3 h4 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 16px;
}
footer div.footer-section3 div.socials-cont a {
    margin-right: 16px;
}
footer div.footer-section3 div.socials-cont a:last-child {
    margin-right: 0;
}
footer div.footer-section3 div.socials-cont a img {
    max-width: 42px;
}

footer div.privacy-policy {
    display: flex;
    min-height: 48px;
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;

    background-color: #379144;
}
footer div.privacy-policy p {
    text-align: center;
    margin: auto;
}

@media (min-width: 1280px) {    
    footer div.section-cont > div {
        margin-right: 100px;
    }
    footer div.section-cont {
        justify-content: center;
    }

}

@media only screen and (max-width: 600px) {
    footer div.section-cont > div:last-child {
        margin-right: 0;
    
        margin-left: 0px;
    }
}