/* Nav Phirst Serv */
  .nav-phirst-serv {
    z-index: 5000;
    position: fixed;
    display: flex;
    width: 100vw;
    max-width: 100%;
    height: 80px;
    box-sizing: border-box;
    /* background-color: rgb(255, 255, 255, 0.7); */
    background-color: #FFFFFF;
  }
  .nav-phirst-serv ul.nav-links {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin-left: auto;
    margin-right: 4%;
    max-width: 80%;
  }
  .nav-phirst-serv ul.nav-links li {
    font-weight: bold;
  }
  .nav-phirst-serv ul.nav-links li a {
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    color: #404041;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.032em;
    border-bottom: solid 2px transparent;
    padding: 5px 10px;
  }
  .nav-phirst-serv ul.nav-links li a.active {
    font-weight: 500;
  }
  .nav-phirst-serv ul.nav-links li a:not(.active):hover {
    color: #379144;
  }
  .nav-phirst-serv ul.nav-links ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
  }
  .nav-phirst-serv ul.nav-links ul li {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .nav-phirst-serv ul.nav-links ul li a {
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  .nav-phirst-serv ul.nav-links ul li a.active {
    color: #379144;
  }
  .nav-phirst-serv ul.nav-links li.nav-item {
  }
  .nav-phirst-serv ul.nav-links li.nav-item .nav-link {
    display: block;
    padding: 10px;
    color: #404041;
  }
  /* Style the dropdown menu */
  .nav-phirst-serv ul.nav-links li.nav-item .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255, 0.7);
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 5px;

    box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
    
  }
  .nav-phirst-serv ul.nav-links li.nav-item .dropdown-menu li {
  }
  .nav-phirst-serv ul.nav-links li.nav-item .dropdown-menu li a {
    color: #404041;
    font-weight: 500;
    text-transform: none;
  }
  .nav-phirst-serv ul.nav-links li.nav-item .nav-link {
    border-bottom: solid 2px transparent;
  }
  .nav-phirst-serv ul.nav-links li.nav-item .nav-link:hover {
    border-color: transparent;
  }
  .nav-phirst-serv ul.nav-links li.nav-item .dropdown-menu li a:hover {
    color: #379144;
  }
  .nav-phirst-serv ul.nav-links li.nav-item .dropdown-menu li a.active {
  }
  .nav-phirst-serv ul.nav-links li.nav-item .dropdown-menu li a.active {
    color: #379144;
  }
  /* Show the dropdown menu on hover */
  .nav-phirst-serv ul.nav-links li.nav-item:hover .dropdown-menu {
    display: block;
  }
  .nav-phirst-serv .logo-cont {
    position: relative;
    display: flex;
    height: inherit;
    align-items: center;
  }
  .nav-phirst-serv .logo-cont .logo-anchor {
    z-index: 3000;
    position: relative;
    margin: auto;
    margin-left: 60px;
  }
  .nav-phirst-serv .logo-cont .logo {
    height: 100%;
    max-height: 70px;
  }
  .nav-phirst-serv .logo-cont .logo-bar {
    z-index: 2900;
    position: absolute;
  }
  .nav-phirst-serv .mobile-menu-btn {
    display: block;
    max-height: 36px;
    margin: auto 0;
    margin-left: auto;
    margin-right: 24px;
  }
  .nav-phirst-serv .mobile-menu-dropdown {
    position: fixed;
    top: 72px;
    right: 0;
    border-left: solid 4px #404041;
    background-color: #379144;
    color: #fff;
    font-family: "Lato";
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: calc(100% - 72px);
    transform: translateX(calc(100% + 16px));
    transition: all 320ms ease-in-out;
  }
  .nav-phirst-serv .mobile-menu-dropdown.show {
    transform: translateX(0);
    transition: all 480ms ease-in-out;
  }
  .nav-phirst-serv .mobile-menu-dropdown ul {
    margin: 0;
    list-style-type: none;
    margin-right: 8px;
  }
  .nav-phirst-serv .mobile-menu-dropdown > ul {
    padding-inline-start: 0;
  }
  .nav-phirst-serv .mobile-menu-dropdown > ul > li {
    margin-bottom: 4px;
    text-transform: uppercase;
  }
  .nav-phirst-serv .mobile-menu-dropdown > ul > li > ul {
    padding-inline-start: 24px;
  }
  .nav-phirst-serv .mobile-menu-dropdown ul a {
    display: block;
    color: #fff;
    text-decoration: none;
    text-align: right;
    letter-spacing: 0.02em;
    font-weight: 500;
    width: 100%;
    padding: 8px;
    padding-right: 16px;
    box-sizing: border-box;
  }
  .nav-phirst-serv .mobile-menu-dropdown ul a.active {
    background-color: #404041;
  }
  .nav-phirst-serv .mobile-menu-dropdown > ul > li > ul > li > a {
    text-transform: none;
  }
  .nav-phirst-serv.landing-page {
    position: fixed;
    top: 0;
    height: 80px;
    background: transparent;
    background-color: rgb(255, 255, 255, 0.7);
  }
  .nav-phirst-serv.landing-page ul.nav-links > li {
  }
  .nav-phirst-serv.landing-page ul.nav-links {
  }
  .nav-phirst-serv.landing-page .logo-cont .logo-bar,
  .nav-phirst-serv.landing-page .logo-cont .logo-anchor {
  }
  .nav-phirst-serv.landing-page ul.nav-links li a {
    white-space: nowrap;
    padding: 4px 10px;
  }
  .nav-phirst-serv.landing-page ul.nav-links li.nav-item .nav-link,
  .nav-phirst-serv.landing-page ul.nav-links > li > a {
    min-width: 0px;
    text-align: center;
  }
  .nav-phirst-serv.landing-page ul.nav-links > li > a:not(.active):hover {
  }
  .nav-phirst-serv.landing-page ul.nav-links li.nav-item .dropdown-menu {
    position: absolute;
    background-color: rgb(255, 255, 255, 0.7);
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 3px;
  }
  .nav-phirst-serv.landing-page ul.nav-links li.nav-item .dropdown-menu > li > a {
    padding: 8px 12px;
  }
  .nav-phirst-serv .landing-page-logo {
  }
  
  .nav-phirst-serv.landing-page .logo-cont .logo-bar {
    display: none;
  }
  .nav-phirst-serv ul.nav-links li img {
    max-width: 25px;
    width: 25px;
    margin: 0px !important;
  }
  .nav-phirst-serv.landing-page ul.nav-links > li > img {
    padding: 0px !important;
    min-width: 0px !important;
  }
  /* Brands */
  #navbar > ul > li.nav-item.dropdown > a {
    /* margin-top: 2px; */
    margin-top: 3px;
    
  }
  /* Social Icons */
  /* #navbar > ul > li:nth-child(6) {
      margin: 10px;
  }
  #navbar > ul > li:nth-child(7) {
      margin: 10px;
  }
  #navbar > ul > li:nth-child(8) {
      margin: 5px;
  }
  #navbar > ul > li:nth-child(9) {
      margin: 10px;
  } */
  .social {
    display: flex;
    gap: 2px;
  }
  .social a {
    padding: 5px 0 0 0 !important;
  }
  #navbar > ul > li:nth-child(6) > a {
  }
  .nav-phirst-serv ul.nav-links li a.active {
    background-color: transparent;
    color: #379144;
  }
  
  /* Nav Custom */
  @media (max-width: 1024px) {
    .nav-phirst-serv {
      height: 72px;
    }
    .logo-bar.desktop {
      display: none;
    }
    .nav-phirst-serv .logo-cont .logo-anchor {
      margin-left: 42px;
      transform: translateY(-2px);
    }
    .nav-phirst-serv .logo-cont .logo {
      max-height: 32px;
    }
  }
  @media (max-width: 1080px) {
    .nav-phirst-serv ul.nav-links.desktop {
      display: none;
    }
    .nav-phirst-serv.landing-page .mobile-menu-dropdown {
      z-index: 2000;
      top: 0px;
      max-height: 100%;
      border-left: none;
    }
    .nav-phirst-serv.landing-page .mobile-menu-btn {
      z-index: 2001;
      margin-right: 10px;
    }
    .nav-phirst-serv.landing-page .mobile-menu-dropdown > ul {
      margin-top: 60px;
    }
  }
  @media (max-width: 1280px) {
    .nav-phirst-serv ul.nav-links {
      margin-right: 12px;
    }
  }
  @media (min-width: 1024px) {
    .logo-bar.mobile {
      display: none;
    }
    .nav-phirst-serv .mobile-menu-dropdown {
      top: 90px;
    }
  }
  @media (min-width: 1080px) {
    .nav-phirst-serv .mobile-menu-btn {
      display: none;
    }
    .nav-phirst-serv .mobile-menu-dropdown {
      display: none;
    }
  }
  