@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'Lato';
  font-weight: 200;
  src: url('../src/fonts/Lato-Thin.ttf');
}
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('../src/fonts/Lato-Light.ttf');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('../src/fonts/Lato-Regular.ttf');
}
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: url('../src/fonts/Lato-Bold.ttf');
}
@font-face {
  font-family: 'Agatho';
  font-weight: 300;
  src: url('../src/fonts/Agatho-Light.otf');
}
@font-face {
  font-family: 'Agatho';
  font-weight: 400;
  src: url('../src/fonts/Agatho-Regular.otf');
}
@font-face {
  font-family: 'Agatho';
  font-weight: 500;
  src: url('../src/fonts/Agatho-Medium.otf');
}
@font-face {
  font-family: 'Agatho';
  font-weight: 600;
  src: url('../src/fonts/Agatho-Bold.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@font-face {
    font-family: 'Corporate';
    src: url('../src/fonts/corporate-coming-soon-font.ttf');
}
@font-face {
    font-family: 'Optima';
    src: url('../src/fonts/OPTIMA.TTF');
}
@font-face {
    font-family: 'Caveat';
    src: url('../src/fonts/Caveat-Regular.ttf');
}
@font-face {
    font-family: 'Manta';
    src: url('../src/fonts/Manta-Style-Script.ttf');
}
@font-face {
    font-family: 'Feel-Free';
    src: url('../src/fonts/FeelfreePersonalUseRegular.ttf');
}
@font-face {
  font-family: 'LT Bulletin';
  src: url('../src/fonts/LTBulletin-Medium.ttf');
}
@font-face {
  font-family: 'Omega Sans';
  src: url('../src/fonts/Omega-Sans-Regular.ttf');
}
@font-face {
  font-family: 'Authentic';
  src: url('../src/fonts/Authentic-Signature.otf');
}
@font-face {
  font-family: 'Ethereal';
  src: url('../src/fonts/Ethereal-Thin.otf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: overlay;
}
* {
  font-family: 'Lato';
}
h1, h2, h3, h4, h5, h6 {
  /* font-family: 'Montserrat'; */
  font-family: 'Lato';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  position: relative;

  width: 100vw;
  max-width: 1920px;
  height: 100vh;
  /* max-height: calc(100vh - 90px); */
  box-sizing: border-box;

  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
}
/* width */
/* .content::-webkit-scrollbar {
  width: 8px;

  background: transparent;
} */
.content::-webkit-scrollbar * {
  /* width: 8px; */

  background: transparent !important;
}
/* Handle */
.content::-webkit-scrollbar-thumb {
  /* background: #379144; */
  background: #379144 !important;

  border-radius: 10px;

}

.content::-webkit-scrollbar-corner {
  border-radius: 10px;
  /* background: #379144; */
  background: #379144 !important;
}

::-webkit-scrollbar * {
  /* width: 8px; */

  background: transparent !important;
}

::-webkit-scrollbar-track * {
  background: transparent !important;
}

.back-btn-v1 {
  z-index: 1000;
  position: fixed;
  top: 120px;
  left: 42px;
  max-width: 48px;
  cursor: pointer;
}   

.back-btn-v1:active {
  transform: scale(.9);
}

@media (max-width: 1024px) {
  .content {
      max-height: calc(100vh - 72px);
  }
}

@media (max-width: 576px) {  
  .back-btn-v1 {
    top: 84px;
    left: 8px;
    max-width: 36px;
  }
}