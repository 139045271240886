@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sora:wght@100..800&display=swap");

.explore-container {
  display: flex;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}
.explore-container .explore-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 65vh;
  max-height: 720px;
  gap: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/Explore/Explorebg.jpg");
}
.explore-container .explore-hero img {
  width: 3rem;
}
.explore-container .explore-hero h1 {
  font-family: "Playfair Display", serif !important;
  color: white;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 0%;
}
.explore-container .explore-content {
  width: 100%;
  max-width: 768px;
}
.explore-container .explore-content .article-content {
  padding: 16px;
  margin: 36px 0;
}
.explore-container .explore-content .article-date {
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 4px;
  color: #379144;
  border-bottom: 1px solid #379144;
}

.explore-container .explore-content .article-content h1 {
  font-family: "Playfair Display", serif !important;
  font-weight: 400;
  font-size: 42px;
  color: #404041;
  line-height: 120%;
}

.explore-container .explore-content .article-content .children-content {
  color: #404041;
  font-family: "Roboto";
  padding-bottom: 24px;
  line-height: 150%;
  font-size: 16px;
}
.explore-container .explore-content .article-content .children-content img {
  width: 100%;
  margin-bottom: 16px;
}
.explore-container .explore-content .article-content a.social-link {
  font-family: "Playfair Display", serif;
  color: #404041;
  padding-bottom: 24px;
  line-height: 150%;
  font-size: 14px;
  text-decoration: none;
}

.explore-container .explore-content .article-content .read-more a {
  text-transform: uppercase;
  border: 1px solid black;
  padding: 12px 32px;
  cursor: pointer;
  font-weight: 600;
  color: #404041;
  transition: all 200ms ease-in;
  text-decoration: none;
}

.explore-container .explore-content .article-content a.social-link:hover {
  text-decoration: underline;
}

.explore-container .explore-content .article-content .read-more a:hover {
  background-color: #404041;
  color: white;
}
@media (max-width: 768px) {
  .explore-container .explore-hero {
    height: 40vh;
  }
  .explore-container .explore-content .article-content {
    padding: 32px;
  }
}
@media (max-width: 640px) {
  .explore-container .explore-hero {
    height: 50vh;
  }
  .explore-container .explore-hero img {
    width: 2rem;
  }
  .explore-container .explore-hero h1 {
    font-size: 28px;
  }
  .explore-container .explore-content .article-content h1 {
    font-size: 26px;
  }
  .explore-container .explore-content .article-content p {
    font-size: 14px;
  }
}
