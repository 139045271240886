.content.centrale div.banner {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 420px;
  box-sizing: border-box;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.content.centrale div.banner .color-overlay {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(250, 250, 250, 0.85) 48%,
    transparent
  );
}
.content.centrale div.banner .text-content {
  z-index: 300;
  color: #fff;
  width: 100%;
  max-width: 90%;
  margin: auto;
  margin-bottom: 127px;
  text-align: center;
}
.content.centrale div.banner .text-content h1 {
  font-family: "Lato";
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
}
.content.centrale div.banner .text-content h1 span {
  font-family: "Lato";
}
.content.centrale div.banner .text-content p {
  font-size: 14px;
  line-height: 1.25em;
  margin: 0;
  color: #404041;
  margin-top: 25px;
}
.content.centrale div.properties {
  position: relative;
  width: 100%;
  padding: 32px 0;
  background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/textured-bg.jpg");
  background-size: 100%;
  background-position: center;
  background-repeat: repeat-y;
}
.content.centrale div.properties h2 {
  font-family: "Lato";
  font-size: 20px;
  text-align: center;
  color: #404041;
  margin: 0 auto;
  margin-top: 12px;
  max-width: 600px;
}
.content.centrale div.properties div.cont-4es {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}
.content.centrale div.properties div.cont-4es p {
  margin: 0;
  text-align: center;
}
.content.centrale div.properties div.cont-4es p span {
  font-family: "LT Bulletin";
  font-weight: bold;
  font-style: italic;
  font-size: 1.5em;
  letter-spacing: 1px;
  color: #e5a724;
  margin-right: 2px;
}
.content.centrale div.video-embed {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 42px 0 0 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.content.centrale div.video-embed iframe {
  aspect-ratio: 16/9;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.header-img-centrale {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .header-img-centrale {
    margin-top: 150px !important;
  }
}
@media (min-width: 1023px) {
  .content.centrale div.banner {
    min-height: 500px;
  }
  .content.centrale div.banner .text-content {
    max-width: 840px;
  }
  .content.centrale div.banner .text-content h1 {
    font-size: 42px;
  }
  .content.centrale div.banner .text-content p {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .content.centrale div.properties div.cont-4es p {
    font-size: 20px;
  }

  .content.centrale div.banner {
    background-attachment: fixed;
    background-position: 100% 0%;
  }
}
@media (min-width: 1600px) {
  .content.centrale div.banner {
    min-height: 600px;
  }
  .content.centrale div.banner .text-content h1 {
    font-size: 54px;
  }
}
@media (min-width: 1900px) {
  .content.centrale div.banner .text-content {
    max-width: 1000px;
  }

  .content.centrale div.banner .text-content h1 {
    font-size: 60px;
  }
}
.centraleBtn {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #4e2e61;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  max-width: 17rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin: 32px auto;
}

.centrale__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: #4e2e61;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.centraleBtn:hover {
  background-color: white;
  color: #4e2e61;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.centrale__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.centraleBtn:hover .centrale__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.centraleBtn:hover .centrale__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}
