
.content.jobs .iframe-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.content.jobs .iframe-container iframe{
    width: 100%;
    height: 100%;
    border: none;
}


.content.jobs .career-container {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100%;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/careers-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.content.jobs .career-container .career-list {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: 0 auto;

    padding-top: 60px;
    padding-bottom: 60px;
}

.content.jobs .career-container .career-list .career-item {
    display: flex;
    margin: 24px 0;

    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;

    min-height: 280px;
    /* max-height: 280px; */

    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .32);
}


.content.jobs .career-container .career-list .career-item .content-left *,
.content.jobs .career-container .career-list .career-item .content-right * {
    margin: 0;
}

.content.jobs .career-container .career-list .career-item .content-left,
.content.jobs .career-container .career-list .career-item .content-right {
    padding: 20px;
    box-sizing: border-box;
}

.content.jobs .career-container .career-list .career-item .content-left {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 320px;
    height: 100%;
    background-image: linear-gradient(25deg, #522D6D, #4197CB);
}
.content.jobs .career-container .career-list .career-item .content-left .text-content {
    display: flex;
    flex-direction: column;
    color: #fff;
    height: inherit;
}
.content.jobs .career-container .career-list .career-item .content-left .text-content a {
    width: 100%;
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #fff;
    background-color: #379144;

    padding: 8px;
    box-sizing: border-box;
    border: solid 3px #fff;

    margin-top: auto;
}


.content.jobs .career-container .career-list .career-item .content-left .text-content h5 {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 12px;
}
.content.jobs .career-container .career-list .career-item .content-left .text-content p {
    display: flex;
    font-size: 16px;
    margin-bottom: 4px;
}
.content.jobs .career-container .career-list .career-item .content-left .text-content p span {
    font-weight: 500;
    min-width: 140px;
}


.content.jobs .career-container .career-list .career-item .content-right {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    min-width: 580px;
    max-width: 580px;
}
.content.jobs .career-container .career-list .career-item .content-right .section-blur {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(0deg, rgba(250, 250, 250, 1) 50%, rgba(250, 250, 250, 0)) ;
}

.content.jobs .career-container .career-list .career-item .content-right .text-content {
    display: flex;
    flex-direction: column;

    height: inherit;
    overflow: hidden;
}
.content.jobs .career-container .career-list .career-item .content-right .text-content > div {
    /* margin-bottom: 16px; */
}
.content.jobs .career-container .career-list .career-item .content-right .text-content > div ul {
    padding-inline-start: 24px;
}
.content.jobs .career-container .career-list .career-item .content-right .text-content > div ul li {
    font-size: 15px;
    margin-bottom: 4px;
}
.content.jobs .career-container .career-list .career-item .content-right .text-content > div h5 {
    font-size: 18px;
    margin-bottom: 8px;
}
.content.jobs .career-container .career-list .career-item .content-right .text-content > div p {
    text-align: justify;
    line-height: 1.25em;
    max-width: 96%;
    font-size: 15px;
}