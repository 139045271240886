.content.careers .banner {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 720px;

}

.content.careers .banner:hover .carousel-left-control {
    left: 20px;
    opacity: 1;
}
.content.careers .banner:hover .carousel-right-control {
    right: 20px;
    opacity: 1;
}
.content.careers .banner .carousel-left-control {
    z-index: 120;
    position: absolute;
    top: 50%;
    left: -48px;
    transform: translateY(-50%);

    opacity: 0;
    transition: all 480ms ease-in-out;
}
.content.careers .banner .carousel-right-control {
    z-index: 120;
    position: absolute;
    top: 50%;
    right: -48px;
    transform: translateY(-50%);

    opacity: 0;
    transition: all 480ms ease-in-out;
}
.content.careers .banner .carousel-left-control img,
.content.careers .banner .carousel-right-control img {
    max-width: 36px;
    border: solid 2px #fff;
    border-radius: 42px;
    cursor: pointer;
}

.content.careers .banner .banner-carousel {
    z-index: 100;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
.content.careers .banner .banner-item {
    position: relative;
    display: flex;
    min-width: 100%;
    height: 100%;

    /* background-size: 100%; */
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
}
.content.careers .banner .banner-item:nth-child(3) {
    background-position: right center;
}
.content.careers .banner .banner-item:nth-child(4) {
    background-position: 100% 80%;
}
.content.careers .banner .banner-item .background-overlay {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #379144;
    opacity: 0;
}
.content.careers .banner .banner-item .banner-content {
    z-index: 120;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    margin-left: 5%;
    background-color: rgba(250, 250, 250, 1);
    max-width: 540px;

    border: solid 3px rgba(64, 64, 65, .8);
    border-radius: 12px;

    padding: 28px;
    padding-bottom: 24px;
    /* color: #fff; */
}
.content.careers .banner .banner-item .banner-front-content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    display: none;
}

.content.careers .banner .banner-item .banner-front-content.mobile {
    display: none;
}


.content.careers .banner .banner-item .banner-content .banner-logo-2 {
    margin-top: -90px;
    /* margin-left: 16px; */
}
.content.careers .banner .banner-item:nth-child(3) .banner-content {
    align-items: flex-end;
    margin-left: auto;
    margin-right: 5%;
    max-width: 500px;
}
.content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
    text-align: right;
    /* max-width: 600px; */
}

.content.careers .banner .banner-item:nth-child(4) .banner-content {
    align-items: flex-end;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: 5%;
    max-width: 500px;
}
.content.careers .banner .banner-item:nth-child(4) .banner-content h2 {
    text-align: right;
    /* max-width: 600px; */
    margin-bottom: 20px;
}


.content.careers .banner .banner-item .banner-content img.banner-logo {
    max-width: 140px;
    margin-bottom: 12px;
}
.content.careers .banner .banner-item .banner-content img.banner-logo-2 {
    max-width: 90px;
    /* margin-bottom: 12px; */
    border-radius: 4px;
}

.content.careers .banner .banner-item .banner-content h2 {
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-size: 40px;
    text-align: left;
    color: #379144;
    line-height: 1.15em;
    /* max-width: 720px; */
    margin-top: 6px;
    margin-bottom: 12px;
}
.content.careers .banner .banner-item .banner-content a {
    display: inline-block;
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    text-align: center;
    text-decoration: none;

    color: #fff;
    background-color: #379144;

    border: solid 2px #379144;
    border-radius: 42px;
    box-sizing: border-box;
    padding: 6px;
    min-width: 160px;

    transition: all 240ms ease-in-out;
}
.content.careers .banner .banner-item .banner-content a:hover {
    color: #379144;
    background-color: transparent;
}




.content.careers .career-cards {
    position: relative;
    width: 100%;
    /* display: flex; */
    display: none;
    flex-direction: column;
    padding: 32px 0;

    background-color: #e6e6d0;
}
.content.careers .career-cards > iframe {
    min-height: 500px;
	width: 100%;
    border: none;
    overflow: hidden;
}
.content.careers .career-cards h4 {
    align-self: flex-start;
    display: inline-block;
    width: auto;
    font-size: 36px;
    color: #379144;
    margin: 0;
    margin-left: 10%;
}

.content.careers .career-cards a {
    display: inline-block;
    align-self: flex-start;
    
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-weight: 500;
    text-align: center;
    text-decoration: none;

    /* color: #379144; */
    color: #404041;

    padding-bottom: 2px;
    border-bottom: solid 2px transparent;

    margin: auto;
}

.content.careers .career-cards a:hover {
    border-color: #404041;
}

.content.careers .why-phirst {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 420px;

    box-sizing: border-box;
    padding: 42px 24px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.content.careers .why-phirst .text-content {
    max-width: 1000px;
    color: #fff;

    margin: auto;
}
.content.careers .why-phirst .text-content h4 {
    font-size: 32px;
    margin: 0;
    margin-bottom: 16px;
}
.content.careers .why-phirst .text-content p {
    font-size: 18px;
    /* font-weight: 300; */
    line-height: 1.25em;
}


.content.careers .divider-phirst {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 360px;

    padding: 48px 24px;
    box-sizing: border-box;

    background-size: cover;
    background-position: 100% 85%;
    background-repeat: no-repeat;
}
.content.careers .divider-phirst .background-overlay {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* background-color: #fff; */
    background-image: linear-gradient(to right, #404041, #fff, #fff ,#404041);
    opacity: .8;
}
.content.careers .divider-phirst .text-content {
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 320px;
}
.content.careers .divider-phirst .text-content img {
    max-width: 72px;
    margin-bottom: 12px;
}
.content.careers .divider-phirst .text-content p {
    text-align: center;
    margin: 0;
    margin-bottom: 16px;
}
.content.careers .divider-phirst .text-content a {
    display: inline-block;
    text-decoration: none;

    box-sizing: border-box;
    border-radius: 42px;
    padding: 8px 24px;
    color: #fff;
    background-color: #379144;
}

.content.careers .map-embed {
    max-height: 600px;
}

@media (max-width: 1600.98px) {
    .content.careers .career-cards > iframe {
        min-height: 540px;
    }
}
@media (max-width: 1280.98px) {
    .content.careers .banner .banner-item {
        /* background-size: cover; */
        background-size: 100%;
    }
    
    .content.careers .banner:hover .carousel-left-control {
        left: 12px;
    }
    .content.careers .banner:hover .carousel-right-control {
        right: 12px;
    } 

    .content.careers .banner .banner-item:nth-child(3) .banner-content {
        margin-bottom: 80px;
    }
    .content.careers .banner .banner-item:nth-child(1) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
        max-width: 540px;
    }
    .content.careers .banner .banner-item:nth-child(2) .banner-content h2 {
        max-width: 420px;
    }
}
@media (max-width: 1024.98px) {
    .content.careers .banner {
        max-height: 540px;
    }
    .content.careers .banner .banner-item .banner-content h2 {
        font-size: 40px;
        margin-bottom: 12px;
    }
    .content.careers .banner .banner-item:nth-child(1) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(2) .banner-content h2 {
        max-width: 440px;
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
        max-width: 400px;
    }
    .content.careers .banner .carousel-left-control img, .content.careers .banner .carousel-right-control img {
        max-width: 54px;
        border-radius: 54px;
        border-width: 4px;
    }
    .content.careers .banner .carousel-left-control {
        left: 32px;
        bottom: 0;
        opacity: 1;
    }
    .content.careers .banner .carousel-right-control {
        right: 32px;
        bottom: 0;
        opacity: 1;
    }
    .content.careers .banner:hover .carousel-left-control {
        left: 32px;
    }
    .content.careers .banner:hover .carousel-right-control {
        right: 32px;
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content {
         margin-bottom: 120px;
    }
    .content.careers .banner .banner-item:nth-child(1) .banner-content {
        margin-left: 36px;
    }
    .content.careers .banner .banner-item:nth-child(2) .banner-content {
        margin-right: 42px;
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content {
        margin-right: 32px;
        margin-bottom: 120px;
    }
    .content.careers .career-cards > iframe {
        min-height: 940px;
    }
}
@media (max-width: 768.98px) {
    .content.careers .banner .banner-item .banner-content h2 {
        font-size: 36px;
        margin-bottom: 12px;
    }
    .content.careers .banner .banner-item:nth-child(1) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(2) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
        max-width: 420px;
    }
    .content.careers .banner .banner-item:nth-child(1) .banner-content {
        margin-left: 42px;
    }
    .content.careers .banner .banner-item:nth-child(2) .banner-content {
        margin-right: 42px;
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content {
        margin-right: 32px;
        margin-bottom: 100px;
    }
    .content.careers .banner .carousel-left-control {
        left: 24px;
        top: unset;
        bottom: 0;
        opacity: 1;
    }
    .content.careers .banner .carousel-right-control {
        right: 24px;
        top: unset;
        bottom: 0;
        opacity: 1;
    }
    .content.careers .banner:hover .carousel-left-control {
        left: 24px;
    }
    .content.careers .banner:hover .carousel-right-control {
        right: 24px;
    }
    .content.careers .career-cards > iframe {
        min-height: 940px;
    }
    .content.careers .banner .banner-item:nth-child(4) .banner-content h2 {
        max-width: 420px;
    }
}
@media (max-width: 576.98px) {
    
    .content.careers .banner .banner-item .banner-front-content.desktop {
        display: none;
    }
    .content.careers .banner .banner-item .banner-front-content.mobile {
        display: block;
        width: 100%;
    }
    .content.careers .banner {
        max-height: 480px;
    }
    .content.careers .banner .banner-item .banner-content h2 {
        font-size: 28px;
        margin-bottom: 12px;
    }
    .content.careers .banner .banner-item .banner-content {
        margin: auto;
    }
    .content.careers .banner .banner-item:nth-child(2) .banner-content,
    .content.careers .banner .banner-item:nth-child(4) .banner-content,
    .content.careers .banner .banner-item:nth-child(3) .banner-content {
        max-width: 75%;
        margin: auto;
    }

    .content.careers .banner .banner-item:nth-child(1) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(2) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
        max-width: 360px;
    }
    .content.careers .banner .carousel-left-control img, .content.careers .banner .carousel-right-control img {
        max-width: 36px;
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content {
        margin-bottom: 120px;
    }
    .content.careers .banner .banner-item .banner-content img.banner-logo {
        max-width: 120px;
    }
    .content.careers .banner .carousel-left-control {
        left: 16px;
        top: unset;
        bottom: 0;
        opacity: 1;
    }
    .content.careers .banner .carousel-right-control {
        right: 16px;
        top: unset;
        bottom: 0;
        opacity: 1;
    }
    .content.careers .banner:hover .carousel-left-control {
        left: 16px;
    }
    .content.careers .banner:hover .carousel-right-control {
        right: 16px;
    }
    .content.careers .career-cards > iframe {
        min-height: 1380px;
    }
}
@media (max-width: 375.98px) {
    .content.careers .banner .carousel-left-control img,
    .content.careers .banner .carousel-right-control img {
        max-width: 32px;
    }
    .content.careers .banner {
        max-height: 540px;
    }
    .content.careers .banner .banner-item .banner-content {
        padding: 24px 12px;
        padding-bottom: 12px;
        margin: auto;
    }
    .content.careers .banner .banner-item .banner-content h2 {
        font-size: 24px;
        max-width: 280px;
        margin-bottom: 12px;
    }
    .content.careers .banner .banner-item .banner-content img.banner-logo {
        max-width: 100px;
    }
    .content.careers .banner .banner-item .banner-content img.banner-logo-2 {
        max-width: 72px;
        margin-top: -84px;
    }

    .content.careers .banner .banner-item:nth-child(1) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(2) .banner-content h2,
    .content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
        max-width: 280px;
    }
    
    .content.careers .banner .banner-item:nth-child(1) .banner-content {
        /* margin-left: 32px; */
    }
    .content.careers .banner .banner-item:nth-child(2) .banner-content {
        /* margin-right: 32px; */
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content {
        /* margin-right: 32px; */
        margin-bottom: 120px;
    }
}
@media (max-width: 360px) {
    .content.careers .banner .banner-item .banner-content h2 {
        font-size: 24px;
        max-width: 280px;
        margin-bottom: 12px;
    }
    .content.careers .banner .banner-item:nth-child(3) .banner-content h2 {
        margin-bottom: 12px;
    }
    .content.careers .banner .banner-item .banner-content img.banner-logo {
        max-width: 80px;
    }
    .content.careers .banner .banner-item .banner-content a {
        font-size: 14px;
        min-width: 140px;
    }
}
