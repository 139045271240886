.content.careers .career-cards-v2 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
 
    box-sizing: border-box;
    padding: 42px 8px;
    
    /* background-color: #e6e6d0; */
}
.content.careers .career-cards-v2 > h2 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #379144;
    margin: 0 auto;
}
.content.careers .career-cards-v2 > a {
    text-decoration: none;
    color: #fff;
    margin: 0 auto;
    margin-top: 36px;
    padding: 8px 12px;
    padding-top: 6px;
    border: solid 2px #379144;
    border-radius: 4px;
    background-color: #379144;
    /* background-color: linear-gradient(25deg, #522D6D, #4197CB); */

}
.content.careers .career-cards-v2 > a:hover {
    /* border-color: #404041; */
    background-color: transparent;
    color: #379144;
}

.content.careers .career-cards-v2 .cards-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    width: 100%;
    margin-top: 16px;
}

.content.careers .career-cards-v2 .cards-container .card-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    min-height: 380px;

    margin: 16px;

    padding: 12px;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .42);
    overflow: hidden;
}

.content.careers .career-cards-v2 .cards-container .card-item .background-overlay {
    z-index: 150;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    /* background-image: linear-gradient(25deg, #522D6D, #4197CB); */
    background-image: linear-gradient(25deg, #404041, #379144);
}
.content.careers .career-cards-v2 .cards-container .card-item .card-content {
    z-index: 200;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    padding: 12px;
    box-sizing: border-box;

    color: #fff;
}
.content.careers .career-cards-v2 .cards-container .card-item .card-content h2 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 12px;
}
.content.careers .career-cards-v2 .cards-container .card-item .card-content p {
    font-size: 15px;
    text-align: justify;
    line-height: 1.3em;
    max-width: 98%;
    margin: 0;
}
.content.careers .career-cards-v2 .cards-container .card-item .card-content div.specs ul#specsList {
    margin: 0;
    margin-bottom: 20px;
    padding-inline-start: 20px;
    padding-inline-end: 16px;
}
.content.careers .career-cards-v2 .cards-container .card-item .card-content div.specs ul#specsList li {
    font-size: 14px;
    margin-bottom: 6px;
}
.content.careers .career-cards-v2 .cards-container .card-item .card-content a {
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-size: 15px;

    text-transform: uppercase;
    text-decoration: none;
    text-align: center;


    border: solid 3px #fff;
    border-radius: 48px;
    box-sizing: border-box;

    padding: 8px 32px;
    
    min-width: 120px;

    /* color: #fff; */
    color: #808080;
    /* background-color: #379144; */
    /* margin: 12px auto; */
    margin-top: auto;

    /* background-image: linear-gradient(25deg, #522D6D, #4197CB); */
    background-color: #FFFFFF;
}

@media (max-width: 576px) {
    .content.careers .career-cards-v2 > h2 {
        font-size: 24px;
    }
    .content.careers .career-cards-v2 {
        padding-top: 28px;
    }
}