.content.job .job-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 60px 12px;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/careers-bg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.content.job .job-cont .job-content {
    font-size: 15px;
    min-height: 100px;
    width: 100%;
    max-width: 800px;
    
    border: solid 3px #379144;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 28px 24px;
    padding-top: 20px;
    margin: 4px auto;
    background-color: #fff;
    color: #404041;
}
.content.job .job-cont .job-content .job-item {
    display: flex;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: solid 1px #40404144;
}
.content.job .job-cont .job-content .job-item:last-child {
    margin-bottom: 0;
    border-bottom: none;
}
.content.job .job-cont .job-content .job-item p {
    margin: 0;
}
.content.job .job-cont .job-content .job-item p:nth-child(1){
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-weight: 600;
    min-width: 200px;
}

.content.job .job-cont .job-content .job-item:nth-child(1) p:nth-child(2) {
    font-weight: bold;
    text-transform: uppercase;
}

.content.job .job-cont .job-content h2 {
    color: #379144;
    margin: 0;
    margin-bottom: 20px;
}

.content.job .job-cont .job-content h4 {
    font-weight: 600;
    margin: 0;
}
.content.job .job-cont .job-content ul {
    padding-inline-start: 28px;
}
.content.job .job-cont .job-content ul:nth-child(3) {
    margin-bottom: 28px;
}

.content.job .job-cont .job-content ul li {
    font-size: 14px;
    margin-bottom: 4px;
}

.content.job .job-cont .job-content.section-2 p {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 24px;
}