
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10001;


    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    max-height: 100%;

    background-color: #fff;

    transition: opacity 400ms ease-in-out;
}


.loading-screen .loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #379144;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-screen > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-screen .brand-logo {
    width: 100%;
    max-width: 200px;
    margin-bottom: 32px;
    margin-left: -10px;
}