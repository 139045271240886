.flip-card-container {
  margin: auto;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  max-width: 1024px;
  margin-top: 40px;
}

.landscape-flip-card {
  position: relative;
  display: flex;
  width: 380px;
  height: 350px;
  border-radius: 8px;
  box-sizing: border-box;
  perspective: 1000px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 16px;
}

.landscape-flip-text-content {
  z-index: 60;
  position: absolute;
  bottom: 14px;
  left: 14px;
  color: #fff;
  margin: auto;
}

.landscape-flip-text-content .landscape-card-title {
  font-size: 28px;
  font-family: "Lato";
  font-weight: normal;
  text-align: center;
  font-weight: 500;
  text-align: start;
  margin: 0;
}

.landscape-flip-text-content span {
  display: block;
  font-family: "Lato";
  font-weight: 300;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 8px;
}

.landscape-flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 16px;
}

.landscape-flip-card.landscape-show .landscape-flip-card-inner {
  transform: rotateY(180deg);
}

.landscape-flip-card-front,
.landscape-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-sizing: content-box;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 16px;
}

.landscape-flip-card-front {
  display: flex;
}
.landscape-flip-card-front .landscape-color-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
}
.landscape-bg-overlay {
  z-index: 40;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 400ms ease-in-out;
}

.landscape-flip-card-front:hover .landscape-bg-overlay {
  transform: scale(1.25);
}

.landscape-flip-card-back {
  z-index: 1000;
  display: flex;
  color: white;
  transform: rotateY(180deg);
}

.landscape-flip-card-back .landscape-flip-text-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.landscape-flip-card-back .landscape-flip-text-content h5 {
  margin-bottom: 12px;
  font-family: "lato";
  font-size: 20px;
  color: black;
}

.landscape-flip-card-back .landscape-flip-text-content div {
  font-family: "lato";
  font-size: 18px;
  color: black;
}

.landscape-flip-card-back .landscape-flip-text-content a {
  background-color: white;
  text-decoration: none;
  padding: 8px 24px;
  margin-top: 40px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  transition: box-shadow 500ms ease 0s;
}
.landscape-flip-card-back .landscape-flip-text-content a:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.landscape-color-overlay,
.landscape-color-overlay-back {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landscape-color-overlay-back {
  background-image: linear-gradient(25deg, #ffffff, #ffffff);
  opacity: 0.8;
}
@media (max-width: 768px) {
  .flip-card-container {
    display: flex;
  }
  .landscape-flip-card {
    width: 100%;
    height: 350px;
    max-width: 350px;
    margin: 0 8px;
  }
}
@media (max-width: 640px) {
  .landscape-flip-card-back .landscape-flip-text-content div {
    font-family: "lato";
    font-size: 15px;
    color: black;
  }
}
