.content.about .section.stand-out {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;

    padding: 24px 0;

    min-height: 320px;
}

.content.about .section.stand-out .text-content {
    margin: 0 auto;
    margin-bottom: 12px;
}
.content.about .section.stand-out .text-content h2 {
    /* font-family: 'Agatho'; */
    font-size: 24px;
    text-align: center;
    color: #404041;
}

.content.about .section.stand-out .cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    margin: 0 auto;
}

.content.about .section.stand-out .cards .card {
    position: relative;
    display: flex;
    min-height: 20px;
    min-width: 280px;
    max-width: 280px;


    /* border: solid 4px #379144; */
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: 8px 8px 0px rgba(64, 64, 65, 1);

    overflow: hidden;
    margin: 0 16px;
    margin-bottom: 32px;

    transition: all 240ms ease-out;
}

.content.about .section.stand-out .cards .card:hover {
    transform: translateY(-12px);
    transition: all 400ms ease-in-out;
    box-shadow: 12px 16px 0px rgba(64, 64, 65, .32);
}

.content.about .section.stand-out .cards .card .color-overlay {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #379144; */
    /* background-color: transparent; */
    /* background-image: linear-gradient(25deg, #522d6d, #4197cb); */
    background-image: linear-gradient(25deg, #404041, #379144);
    opacity: .8;
}
.content.about .section.stand-out .cards .card .text-content {
    z-index: 60;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    padding-bottom: 12px;
}
.content.about .section.stand-out .cards .card .text-content .card-icon{
    width: 2.5rem;
}
.content.about .section.stand-out .cards .card .text-content * {
    color: #fff;
    margin: 0;
}
.content.about .section.stand-out .cards .card .text-content h4 {
    /* background-color: #fff; */
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 4px;
    text-align: start;
}
.content.about .section.stand-out .cards .card .text-content p {
    text-align: start;
    font-size: 14.5px;
}

@media (min-width: 414px) {
    .content.about .section.stand-out .text-content h2 {
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 24px;
    }
}
@media (min-width: 768px) {
    .content.about .section.stand-out .cards {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .content.about .section.stand-out .cards .card {
        min-height: 200px;
    }
    .content.about .section.stand-out .text-content h2 {
        max-width: none;
    }
}

@media (min-width: 1600px) {
    .content.about .section.stand-out .cards .card {
        min-width: 320px;
        max-width: 320px;
    }
}