nav.navigation li {
    margin: 0 16px;
}
@media (min-width: 992px) and (min-aspect-ratio: 16 / 9) {
    nav.navigation {
        z-index: 9400;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        max-height: 0px;
        background-color: transparent;
        background-image: linear-gradient(rgba(25, 25, 25, 1), rgba(55,55,55, 0));
    }
    nav.navigation .dropdown .desktop {
        position: absolute;
        top: 8px;
        right: 0;
        letter-spacing: .05em;
        padding-inline-start: 0;
        padding: 24px;
        padding-left: 48px;
        margin: 0;
    } 
    nav.navigation .dropdown .desktop li a {
        background-color: #003262;
        padding: 8px 2em;
        border-radius: 500px;
        font-weight: bold;
    }
    nav.navigation .dropdown .desktop li a:hover {
        outline: solid 4px #fff;
        background-color: #ba9b6a;
    }
    .content.main {
        max-height: 100vh;
        overflow-y: hidden;
    }
    nav.navigation * {
        color: #fff;
    }
    nav button {
    }
    nav > div.dropdown .mobile {
        top: 54px;
    }
    nav.navigation img {
        display: none;
    }
}
@media (orientation: landscape) and (aspect-ratio: 9 / 16) {
    nav.navigation {
        z-index: 9400;
        position: fixed;
        top: 8px;
        left: 0;
        right: 0;
        max-height: 0px;
        background-color: transparent;
        background-image: linear-gradient(rgba(25, 25, 25, 1), rgba(55,55,55, 0));
    }
    nav.navigation .dropdown .desktop {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding-inline-start: 0;
        padding: 24px;
        padding-left: 48px;
    }
    nav.navigation .dropdown .desktop li a {
        background-color: #003262;
        padding: 8px 2em;
        border-radius: 500px;
        font-weight: bold;
    }
    nav.navigation .dropdown .desktop li a:hover {
        outline: solid 4px #fff;
        background-color: #ba9b6a;
    }
    .content.main {
        max-height: 100vh;
        overflow-y: hidden;
    }
    nav.navigation * {
        color: #fff;
    }
    nav button {
    }
    nav > div.dropdown .mobile {
        top: 54px;
    }
    nav.navigation img {
        display: none;
    }
}
@media (orientation: landscape) and (min-aspect-ratio: 9 / 20) and (min-width: 992px) {
    nav.navigation {
        z-index: 9400;
        position: fixed;
        top: 8px;
        left: 0;
        right: 0;
        max-height: 0px;
        background-color: transparent;
        background-image: linear-gradient(rgba(25, 25, 25, 1), rgba(55,55,55, 0));
    }
    nav.navigation .dropdown .desktop {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding-inline-start: 0;
        padding: 16px 24px;
        padding-left: 48px;
    }
    nav.navigation .dropdown .desktop li a {
        background-color: #003262;
        padding: 8px 2em;
        border-radius: 500px;
        font-weight: bold;
    }
    nav.navigation .dropdown .desktop li a:hover {
        outline: solid 4px #fff;
        background-color: #ba9b6a;
    }
    .content.main {
        max-height: 100vh;
        overflow-y: hidden;
    }
    nav.navigation * {
        color: #fff;
    }
    nav button {
    }
    nav > div.dropdown .mobile {
        top: 54px;
    }
    nav.navigation img {
        display: none;
    }
}
/* @media (max-aspect-ratio: 16/9) {
    nav.navigation {
        z-index: 9400;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    
        max-height: 80px;

        background-color: transparent;
        background-image: linear-gradient(rgba(25, 25, 25, 1), rgba(55,55,55, 0));
    }
    nav.navigation * {
        color: #fff;
    }
    .content.main {
        max-height: 100vh;
        overflow-y: hidden;
    }
} */
.content.main {
    position: relative;
    width: 100vw;
    max-width: 100%;
    top: 0;
    height: 100vh;
    overflow-x: hidden;
    background-color: transparent;
}
@media (max-width: 1024.98px) {
    .content.main {
        top: 90px;
    }
}
@media (max-width: 768.98px) {
    .content.main {
        height: calc(100vh - 72px);
        top: 72px;
    }
}
.content.main::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/textured-bg-3.jpg') no-repeat center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
}
.content.main > div.mainContent {
    position: relative;
    margin: auto;
}
/* width */
::-webkit-scrollbar {

}
/* Track */
::-webkit-scrollbar-track * {
    display: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #379144 !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #379144 !important;
}
#img-mapper {
    align-self: center;
}
.music-controller {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
}
.music-controller a img {
    max-height: 40px;
}
.music-controller a {
}
.music-controller a:first-child {
    margin-bottom: 8px;
}
.custom-modal {
    z-index: 1000;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    max-height: 0;
    max-width: 320px;
    background-color: #fff;
    border-radius: 24px;
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;
    overflow-y: hidden;
    animation: show 1000ms ease-in-out forwards 100ms;
}
.custom-modal2 {
    z-index: 1000;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    max-height: 0;
    max-width: 360px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;
    animation: show 1000ms ease-in-out forwards 100ms;
}
.custom-modal.night,
.custom-modal2.night {
    box-shadow: 0 0 12px rgba(255, 255, 255, .6);
}
.custom-modal.day,
.custom-modal2.day {
    box-shadow: 0 0 12px rgba(0, 0, 0, .75);
}
.custom-modal *,
.custom-modal2 * {
    opacity: 0;
    animation: opacity 720ms ease-in-out forwards 320ms;
}
@keyframes show {
    0% {
        opacity: 0;
        max-height: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        max-height: 1000px;
    }
}
@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media (max-width: 992px) {
    .custom-modal,
    .custom-modal2 {
        left: 0 !important;
        right: 0 !important;
        margin: auto;
    }
}
.custom-modal .modal-title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 4px;
}
.custom-modal h2,
.custom-modal2 h2, 
.custom-modal h4,
.custom-modal2 h4,
.custom-modal h6,
.custom-modal2 h6,
.custom-modal p,
.custom-modal2 p  {
    margin: 0;
}
.custom-modal .modal-body h4, .custom-modal .modal-body2 h4,
.custom-modal2 .modal-body h4, .custom-modal .modal-body2 h4 {
    margin-top: 6px;
    margin-bottom: 4px;
    font-size: 18px;
    color: #003262;
}
.custom-modal .modal-body h6,
.custom-modal2 .modal-body h6 {
    color: #2f2f2f;
    margin-bottom: 8px;
    font-size: 12px;
}
.custom-modal .modal-body p, .custom-modal .modal-body2 p,
.custom-modal2 .modal-body p, .custom-modal .modal-body2 p {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 300;
    margin-top: 6px;
    margin-bottom: 12px;
}
.custom-modal2 .modal-body p {
    font-size: 15px;
    color: black;
    padding: 0 16px;
    line-height: 1.25em;
    margin: 0 auto;
    margin-bottom: 8px;
    text-align: center;
}
.custom-modal .modal-body:empty::before {
    content: 'Loading';
}
.custom-modal .modal-body .modal-img,
.custom-modal2 .modal-body .modal-img {
    width: 100%;
}
.custom-modal .modal-body .modal-img,
.custom-modal2 .modal-body .modal-img {
    width: 100%;
    height: 100px;
    background-color: #fff;
    background-size: cover;
    background-position: 100% 80%;
    background-repeat: no-repeat;
}
.custom-modal2 .modal-body .modal-img {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 160px;
}
.custom-modal2 .modal-body .modal-img a {
    background-color: #379144;
    display: block;
    height: 32px;
    margin-left: auto;
    margin-top: 0;
    margin-right: 0;
    border-bottom-left-radius: 8px;
}
.custom-modal2 .modal-body .modal-img a:hover {
}
.custom-modal2 .modal-body .modal-img a i {
    color: #fff;
    font-size: 2em;
}
.custom-modal2 .modal-body .modal-img h4 {
    align-self: flex-start;
    margin-top: auto;
    margin-bottom: 0;
    background-color: #ba9b6a;
    color: #fff;
    padding: 8px;
    padding-bottom: 4px;
    border-top-right-radius: 8px;
}
.custom-modal .modal-body .modal-img,
.custom-modal2 .modal-body .modal-img {
}
.custom-modal2 .modal-body > h4 {
    color: #2f2f2f;
    align-self: flex-start;
    box-sizing: border-box;
    padding: 6px 16px;
    margin: auto;
    text-align: center;
    font-size: 28px;
}
.custom-modal2 .modal-body > h4,
.custom-modal2 .modal-body > h4.impressions-title,
.custom-modal2 .modal-body > h4.editions-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Lato";
    font-weight: 400;
    color: #fff;
}
.custom-modal2 .modal-body > h4 span,
.custom-modal2 .modal-body > h4.impressions-title span,
.custom-modal2 .modal-body > h4.editions-title span {
    font-family: 'Caveat';
    font-size: 1.42em;
    font-weight: normal;
    color: #fff;
    margin-left: 4px;
}
.custom-modal2 .modal-body > h4 {
    font-weight: 500;
}
.custom-modal2 .modal-body > h4 span {
    font-family: 'Omega Sans';
} 
.custom-modal2 .modal-body > h4.editions-title span {
    font-family: 'Optima';
}
.custom-modal2 .modal-body,
.custom-modal2 .modal-body.impressions,
.custom-modal2 .modal-body.editions {
    background-color: #4E2E61;
    padding-bottom: 4px;
}
.custom-modal2 .modal-body {
    padding-top: 0;
}
.custom-modal2 .modal-body p,
.custom-modal2 .modal-body.impressions *,
.custom-modal2 .modal-body.editions * {
    color: #fff;
}
.custom-modal2 .modal-body button,
.custom-modal2 .modal-body.impressions button,
.custom-modal2 .modal-body.editions button {
    background-color: #4197CB;
    border-color: #4197CB;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.custom-modal2 .modal-body button:hover, .custom-modal2 .modal-body button:active,
.custom-modal2 .modal-body.impressions button:hover, .custom-modal2 .modal-body.impressions button:active,
.custom-modal2 .modal-body.editions button:hover, .custom-modal2 .modal-body.editions button:active {
    background-color: transparent;
    border-color: #4197CB;
    color: #4197CB;
}
.custom-modal2 .modal-body .modal-img {
    height: 140px;
}
.custom-modal2 .modal-body.impressions .modal-img,
.custom-modal2 .modal-body.editions .modal-img {
    margin: 0 auto;
    height: 140px;
}
.custom-modal2 .modal-body p,
.custom-modal2 .modal-body.impressions p, 
.custom-modal2 .modal-body.editions p {
    font-size: 16px;
    text-align: justify;
    text-align-last: center;
    max-width: 84%;
}
.custom-modal2 .modal-body.impressions .modal-btn,
.custom-modal2 .modal-body.editions .modal-btn {
    margin: 0 auto;
}
.custom-modal2 .modal-body > small {
    display: block;
    margin: 6px 8px;
    font-style: italic;
    font-size: 12px;
    color: #2f2f2f;
}
.custom-modal .modal-body .modal-property {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}
.custom-modal .modal-body2 {
    display: flex;
    flex-direction: row;
}
.custom-modal .modal-body2 div {
    padding: 2px 8px;
}
.custom-modal .modal-body2 img {
    width: 100%;
    border-radius: 6px;
}
.custom-modal .modal-body .additional-details,
.custom-modal2 .modal-body .additional-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 8px;
    box-sizing: border-box;
}
.custom-modal2 .modal-body .additional-details {
    padding: 0 12px;
}
.custom-modal .modal-body .additional-details .detail,
.custom-modal2 .modal-body .additional-details .detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4px 0;
    margin-right: 4px;
}
.custom-modal .modal-body .additional-details .detail:last-child,
.custom-modal2 .modal-body .additional-details .detail:last-child {
    margin-right: unset;
}
.custom-modal .modal-body .additional-details .detail img,
.custom-modal2 .modal-body .additional-details .detail img {
    max-width: 24px;
    margin-bottom: 4px;
}
.custom-modal2 .modal-body .additional-details .detail img {
    max-width: 22px;
}
.custom-modal .modal-body .additional-details .detail h4,
.custom-modal2 .modal-body .additional-details .detail h4 {
    color: black;
    font-size: 14px;
    font-weight: normal;
}
.custom-modal .modal-body .additional-details .detail small,
.custom-modal2 .modal-body .additional-details .detail small {
    color: #4f4f4f;
    font-size: 12px;
}
.custom-modal .modal-body .modal-btn {
    padding-top: 12px;
    padding-bottom: 12px;
}
.custom-modal2 .modal-body .modal-btn {
    padding: 8px 12px;
}
.custom-modal2 .modal-body .modal-btn > p {
    text-align: center;
    margin-bottom: 12px;
    font-family: "Manta Style";
    font-size: 32px;
    color: #4b80a9;
}
img.other-details {
    display: block;
    max-width: 200px;
    margin: auto;
    margin-bottom: 24px;
}
.custom-modal button,
.custom-modal2 button {
    width: 100%;
    padding: 6px 12px;
    border-radius: 500px;
    border: solid 2px #4b80a9;
    color: #fff;
    background-color: #4b80a9;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.custom-modal2 button {
}
.custom-modal button:hover, .custom-modal button:active {
    background-color: transparent;
    color: #48A6D3;
}
.custom-modal2 button:hover, .custom-modal button:active {
    background-color: rgba(229, 167, 36, 1);
    border-color: rgba(229, 167, 36, 1);
}
.custom-video-modal {
    z-index: 1000;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;
    overflow-y: hidden;
    animation: showVideo 1200ms ease-in-out forwards;
}
.custom-video-modal .modal-close {
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
}
.custom-video-modal .modal-close a i {
    color: #fff;
    font-size: 2em;
}
.custom-video-modal .modal-body {
    margin: auto;
    background-color: #003262;
    border-radius: 6px;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 12px;
    padding-top: 8px;
}
@keyframes showVideo {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
area {
    cursor: pointer;
}
.video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
}
.video-placeholder img {
    z-index: 1;
    width: 100%;
}
#img-mapper {
    z-index: 101;
}
.video-placeholder .container {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
/* Then style the iframe to fit in the container div with full height and width */
.video-placeholder .container .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
@media (max-width: 1200px) {
    .custom-modal,
    .custom-modal2 {
        top: 50% !important;
        left: 50% !important;
        right: unset !important;
        transform: translate(-50%, -50%);
        margin: auto;
        max-width: 420px;
        max-height: 1000px;
    }
    .custom-modal2 .modal-body .modal-img h4 {
        min-width: 50%;
        padding-left: 12px;
    }
    .custom-modal2 .modal-body .amenities-desc {
        margin-bottom: 24px;
    }
    @keyframes show {
        0% {
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
}
@media (max-width: 992px) {
    .custom-modal,
    .custom-modal2 {
        position: fixed;
        top: 50% !important;
        left: 0 !important;
        right: 0 !important;
        transform: translateY(-50%);
        max-height: unset;
        animation: none;
        opacity: 1;
        max-width: 400px;
    }
    .custom-modal *,
    .custom-modal2 * {
        animation: none;
        opacity: 1;
    }
    .dark-screen {
        z-index: 999;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: unset;
        background-color: rgba(25, 25, 25, 0.75);
    }
}
@media (max-width: 576px) {
    .custom-modal,
    .custom-modal2 {
    }
    .custom-modal .modal-body,
    .custom-modal2 .modal-body {
        display: flex;
        flex-direction: column;
    }
    .custom-modal img.other-details,
    .custom-modal2 img.other-details {
        margin-top: 4px;
        max-width: 240px;
    }
    .custom-modal .modal-body > div:last-child,
    .custom-modal2 .modal-body > div:last-child {
        margin-top: auto;   
    }
    .custom-modal2 .modal-body > h4 {
        font-size: 24px;
    }
    .custom-modal2 .modal-body p {
        padding: 12px 24px;
        font-size: 16px;
    }
}
@media (max-width: 420px) {
    .custom-modal, .custom-modal2 {
        max-width: 360px;
    }
    .custom-modal2 .modal-body .modal-img {
        border-radius: 0;
    }
    .custom-modal2 .modal-body .modal-img h4 {
        font-size: 24px;
        padding: 12px;
        padding-bottom: 6px;
    }
    .custom-modal .modal-body, .custom-modal2 .modal-body {
    }
    .custom-modal .modal-body > div:last-child, .custom-modal2 .modal-body > div:last-child {
        justify-self: flex-end;
    }
    .custom-modal button, .custom-modal2 button {
        padding: 12px;
    }
    .custom-modal .modal-body .additional-details,
    .custom-modal2 .modal-body .additional-details {
        margin-top: 20px;
        justify-content: space-around;
    }
    .custom-modal .modal-body.properties-body {
        height: auto;
    }
    .custom-modal .modal-body .additional-details .detail,
    .custom-modal2 .modal-body .additional-details .detail {
        width: 100%;
        max-width: 100px;
        margin-bottom: 12px;
    }
}
@media (max-width: 375px) {
    .content.houses .meet-calista .text-cont .additional-details {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .custom-modal2 .modal-body .modal-img h4 {
        font-size: 20px;
    }
}
@media (max-width: 320px) {
    .custom-modal, .custom-modal2 {
        max-width: 300px;
    }
}
@media (orientation: landscape) and (max-height: 420px) {
    .custom-modal,
    .custom-modal2 {
        z-index: 9600;
        top: 0 !important;
        bottom: 0;
        transform: none;
        border-radius: unset;
        height: 100%;
        max-height: calc(100vh - 60px);
        border-radius: 6px;
    }
    .custom-modal .modal-body,
    .custom-modal2 .modal-body {
        display: flex;
        flex-direction: column;
        height: calc(100% - 48px);
    }
    .custom-modal .modal-body .modal-img,
    .custom-modal2 .modal-body .modal-img {
        display: none;
    }
    .custom-modal .modal-body div,
    .custom-modal2 .modal-body div {
        margin-top: auto;
        margin-bottom: unset;
    }
    .custom-modal .modal-body .other-details,
    .custom-modal2 .modal-body .other-details {
        margin-bottom: unset;
    }
    .custom-modal .modal-body .modal-property,
    .custom-modal2 .modal-body .modal-property {
        display: none;
    }
}
@media (orientation: landscape) and (max-height: 375px) {
    .custom-modal,
    .custom-modal2 {
        height: auto;
        max-height: 240px;
    }
    .custom-modal .modal-body .additional-details,
    .custom-modal2 .modal-body .additional-details {
        display: none;
    }
}
.mobile-controls {
    position: relative;
    display: none;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: auto;
}
.mobile-controls .img-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 160px;
    height: 120px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.mobile-controls .img-button h4 {
    display: none;
    z-index: 12;
    font-size: 20px;
    color: #fff;
    text-align: center;
}
.mobile-controls .img-button .bg {
    display: none;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background-color: rgba(55, 55, 55, .65);
}
.mobile-controls .img-button:hover {
}
.mobile-controls .img-button:hover > .bg,
.mobile-controls .img-button:hover > h4 {
    display: block;
}
.mobile-controls .img-button.btn-amenities {
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/amenities-bg.jpg');
}
.mobile-controls .img-button.btn-properties {
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/aboutUs-page/images/Sales-pav-front.jpg');
}
.mobile-controls .img-button.btn-cartland {
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/images/house-cartland.jpg');
}
.mobile-controls .img-button.btn-charles {
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/images/house-charles.jpg');
}
.mobile-controls .img-button.btn-christie {
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/images/house-christie.jpg');
}
.mobile-controls .img-button.btn-corin {
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/images/house-corin.jpg');
}
.mobile-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 32px 12px;
    box-sizing: border-box;
}
.mobile-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 20px;
    box-sizing: border-box;
}
.mobile-button .img-container {
    width: 120px;
    height: 120px;
    border: solid 8px #ba9b6a;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 24px;
    box-sizing: border-box;
    overflow: hidden;
}
.mobile-control .button-image {
    width: 100%;
    height: 100%;
}
.mobile-control .button-title {
    display: block;
    max-width: 120px;
    margin: unset;
    margin-top: 10px;
    color: #003262;
    font-size: 14px;
    text-align: center;
}
@media (max-width: 1200px) {
    .content.main {
        overflow-y: auto;
    }
    .mobile-controls {
        display: flex;
        padding-top: 16px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 40px;
        box-sizing: border-box;
    }
    .mobile-controls .img-button {
        margin-top: 16px;
        margin-bottom: 2px;
        margin-left: 8px;
        margin-right: 8px;

        width: 160px;
    }
    .mobile-controls .img-button h4 {
        font-size: 18px;
    }
    .music-controller {
        right: 20px;
    }
}
@media (orientation: landscape) and (max-width: 992px) {
    .custom-video-modal .modal-body {
        max-width: 480px;
    }
}
@media (max-width: 992px) {
    .mobile-controls .img-button h4 {
        display: block;
    }
    .mobile-controls .img-button .bg {
        display: block;
    }
}
@media (max-width: 768px) {
    .music-controller {
        right: 12px;
    }
}
@media (max-width: 576px) {
    .mobile-controls {
        justify-content: space-evenly;
    }
    .mobile-controls .img-button {
        width: 160px;
    }
    .mobile-controls .img-button h4 {
        font-size: 18px;
    }
    .music-controller {
        right: 6px;
    }
    .music-controller a:first-child {
        margin-bottom: unset;
    }
}
@media (max-width: 425px) {
    .mobile-controls .img-button {
        width: 148px;
        height: 100px;
    }
}
@media (max-width: 375px) {
    .content.main {
        max-height: calc(100vh - 72px);
        overflow-y: auto;
    }
    .mobile-controls {
        padding: 28px 4px;
    }
    .mobile-control {
        justify-content: space-evenly;
    }
    .mobile-controls .img-button {
        width: 132px;
        height: 100px;

        margin-bottom: 20px;
        margin-left: 4px;
        margin-right: 4px;
    }
    .mobile-controls .img-button h4 {
        font-size: 16px;
        letter-spacing: 1px;
    }
}
@media (max-width: 374.98px) {
    .mobile-button {
        padding: 12px 0px;
    }
}
@media (max-width: 320.98px) {
    .content.main {
        top: 60px;
        max-height: calc(100vh - 60px);
    }
    .custom-modal2 .modal-body .modal-img {
        display: none;
    }
    .custom-modal button, .custom-modal2 button {
        padding: 4px 12px;
        font-weight: 400;
    }
    .custom-modal2 .modal-body p {
        padding: 0 16px;
    }
    .custom-modal2 .modal-body > h4.alora, .custom-modal2 .modal-body > h4.amani {
        margin-top: 12px;
    }
    .custom-modal .modal-body, .custom-modal2 .modal-body {
        padding-bottom: 12px;
    }
    .custom-modal2 .modal-body > h4 {
        font-size: 32px;
    }
    .custom-modal2 .modal-body .amenities-desc {
        margin-bottom: 12px;
    }
}
.mobile-control-v2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 576px;
    margin: auto;
    margin-top: 16px;
}
.mobile-control-v2 .mobile-btn-v2 {
    display: flex;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 16px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 400ms ease-in-out;
}
.mobile-control-v2 .mobile-btn-v2.show {
    opacity: 1;
    transform: translateY(0);
}
.mobile-control-v2 .mobile-btn-v2.unit {
    height: 140px;
    max-width: 48%;
}
.mobile-control-v2 .mobile-btn-v2.others {
    height: 180px;
}
.mobile-control-v2 .mobile-btn-v2 a {
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.mobile-control-v2 .mobile-btn-v2 .color-overlay {
    position: absolute;
    z-index: 100;
    background-color: rgba(0, 0, 0, .10);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.mobile-control-v2 .mobile-btn-v2  h5.btn-title {
    display: inline-block;
    margin: 0;
    margin-top: auto;
    z-index: 101;
    align-self: flex-start;
    padding: 4px 16px;
    min-width: 120px;
    text-align: center;
    background-color: #379144;
    color: #fff;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    letter-spacing: .02em;
}
@media (min-width: 768px) {   
    .mobile-control-v2 {
        margin-top: 32px;        
    }
    .mobile-control-v2 .mobile-btn-v2 {
        margin-bottom: 24px;
    }
    .mobile-control-v2 .mobile-btn-v2 h5.btn-title {
        font-size: 20px;
    }
}
@media (min-width: 768.98px) {
    .mobile-control-v2 {
        display: none;
    }
}
.intro-video-v2 {
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: opacity 400ms ease-in-out;
}
.custom-modal2 .modal-body > h4 {
    font-size: 28px;
    margin-top: 8px;
    margin-bottom: 0px;
    text-transform: uppercase;
    padding: 0;
    color: #379144;
}
.custom-modal2 .modal-body > small {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 12px;
}
.custom-modal2 .modal-body > h4.impressions-title, .custom-modal2 .modal-body > h4.editions-title {
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    margin-top: 12px;
    margin-bottom: 12px;
    color: #379144;
}
.custom-modal2 .modal-body > h4.editions-title span {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: -.05em;
    margin-left: .15em;
    color: #379144;
}
.custom-modal2 .modal-body > h4.impressions-title {
    line-height: 1.5em;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #379144;
}
.custom-modal2 .modal-body > h4.impressions-title span {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: .05em;
    margin-left: .15em;
    color: #379144;
}
.custom-modal2 .modal-body button {
    min-width: 160px;
}
.custom-modal2 .modal-body, .custom-modal2 .modal-body.impressions, .custom-modal2 .modal-body.editions {
    background-color: #f0eadd;
}
.custom-modal2 .modal-body p, .custom-modal2 .modal-body.impressions p, .custom-modal2 .modal-body.editions p {
    color: #0f0f0f;
    font-size: 13.5px;
    font-family: 'Lato';
    font-weight: 400;
    text-align: center;
}
.custom-modal2 .modal-body button, .custom-modal2 .modal-body.impressions button, .custom-modal2 .modal-body.editions button {
    font-weight: bold;
    background-color: #379144;
    border-color: #379144;
}
.custom-modal2 .modal-body button:hover, .custom-modal2 .modal-body button:active, .custom-modal2 .modal-body.impressions button:hover, .custom-modal2 .modal-body.impressions button:active, .custom-modal2 .modal-body.editions button:hover, .custom-modal2 .modal-body.editions button:active {
    border-color: #379144;
    color: #379144;
}
.custom-modal2 .modal-body p,
.custom-modal2 .modal-body .amenities-desc {
    margin-bottom: 4px;
}
.content.main {
    top: 0;
}
@media (min-width: 375px) {
    .custom-modal2 .modal-body > h4,
    .custom-modal2 .modal-body > h4.editions-title {
    }
    .custom-modal2 .modal-body > h4.impressions-title {
    }
    .custom-modal2 .modal-body p {
        padding-top: 0;
    }
}
@media (min-width: 1024px) {
    .custom-modal2 .modal-body > h4 {
    }
    .custom-modal2 .modal-body > h4.impressions-title, .custom-modal2 .modal-body > h4.editions-title {
        flex-direction: row;
        align-items: center;
        font-size: 20px;
    }
    .custom-modal2 .modal-body > h4.impressions-title {
        margin-top: 16px;
    }
}