.form-modal {
    z-index: 2000;
    position: fixed;
    display: none;
    flex-direction: column;
    top: 50%;
    left: 50%;

    width: 100%;
    max-width: 720px;
    /* min-height: 420px; */

    padding: 16px 20px;
    padding-bottom: 24px;

    border: solid 4px #379144;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #fff;

    opacity: 0;

    transform: translateX(-50%) translateY(-32%);

    transition: all 240ms ease-out;

}

.form-bg-overlay {
    z-index: 1900;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    max-height: 100%;
    width: 100vw;
    max-width: 100%;
    background-color: #000000;
    opacity: 0;

    transition: all 240ms ease-out;
}
.form-bg-overlay.show {
    opacity: .85;
    transition: all 240ms ease-in-out;
}
.form-modal.show {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transition: all 600ms ease-in-out 120ms;
    /* transform: translateX(-50%) translateY(50%); */
}

.form-modal .modal-header {
    position: absolute;
    top: -48px;
    left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.form-modal .modal-logo {
    max-height: 72px;
    border: solid 2px #fff;
    border-radius: 4px;
}

.form-modal .modal-body {
    display: flex;
    width: 100%;
    
    margin-top: 24px;
}
.form-modal .modal-body form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 94%;
    height: inherit;
    margin: 0 auto;
}

.form-modal .modal-body .input-group {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 12px;
    /* border-bottom: solid 1px #40404181; */
}
.form-modal .modal-body .input-group label,
.form-modal .modal-body .input-group.file p {
    min-width: 160px;
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-weight: 600;
    font-size: 15px;
    color: #404041;
    padding-right: 24px;
}
.form-modal .modal-body .input-group.file label {
    min-width: 140px;
    font-size: 15px;
    text-align: center;
    color: #379144;
    padding: 6px 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    /* background-color: #379144; */
    border: solid 2px #379144;
    box-sizing: border-box;
    cursor: pointer;
}
.form-modal .modal-body .input-group.file label i {
    margin-right: 3px;
}
.form-modal .modal-body .input-group.file label:hover {
    background-color: #379144;
    color: #fff;
}

.form-modal .modal-body .input-group.file span {
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-size: 15px;
    padding: 6px 12px;
    /* background-color: #404041; */
    /* border: solid 2px #404041; */
    box-sizing: border-box;
    width: 100%;
}

.form-modal .modal-body .input-group.file > div {
    display: flex;
    align-items: center;
    
    width: 100%;
}

.form-modal .modal-body .input-group input,
.form-modal .modal-body .input-group select {
    outline-color: #379144;
    width: 100%;
    border-radius: 4px;
    padding: 8px; 
    font-size: 16px;
    box-sizing: border-box;
    background-color: #fff;
}

.form-modal .modal-body .input-group select,
.form-modal .modal-body .input-group select option {
    font-size: 15px;
}
.form-modal .modal-body .input-group input[type='text'] {
    border: solid 1px #404041;
    padding: 6px 8px; 
    border: none;
    border-bottom: solid 1px #404041b4;
    border-radius: 0;
}
.form-modal .modal-body .input-group select {
    padding: 6px 8px; 
    border: none;
    border-bottom: solid 1px #404041b4;
    border-radius: 0;
}
.form-modal .modal-body .input-group input[type='file'] {
    padding: 8px 0;

	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.form-modal .modal-body hr {
    border: none;
    border-bottom: solid 1px #000000;
    width: 100%;
}
.form-modal .close-btn {
    z-index: 400;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #404041;
    padding: 0;
    border: none;
    border-radius: 48px;
    background-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
    height: 35px;
    width: 34px;
    transition: all 100ms ease-in-out;
}

.form-modal .close-btn .bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
    vertical-align: -0.1em;
}
.form-modal .close-btn:hover {
    /* color: #404041; */
    /* transform: scale(1.1); */
    color: #fff;
    background-color: #404041;
}
.form-modal .modal-body .btn-cont {
    display: flex;
    margin-top: 12px;
}
.form-modal .modal-body .btn-cont button {
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-size: 15px;
    text-transform: uppercase;

    /* margin-left: 12px; */
    margin-left: auto;

    color: #fff;
    background-color: #379144;

    border-radius: 4px;
    border: solid 2px #379144;

    padding: 6px 12px;
    cursor: pointer;
}

.form-modal .modal-body .btn-cont button.cancel-btn {
    margin-left: auto;
    background-color: transparent;
    border-color: #404041b0;
    color: #404041b0;
}
.form-modal .modal-body .btn-cont button.cancel-btn:hover {
    background-color: #404041;
    color: #fff;
}

.form-modal .modal-body .btn-cont button:hover {
    color: #379144;
    background-color: transparent;
}
.form-modal .modal-body .btn-cont button:active {
    transform: scale(.95);
}


.application-result {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-70%);
    min-height: 240px;
    width: 100%;
    max-width: 320px;
    background-color: #fff;
    padding: 16px 24px;
    border-radius: 6px;
    border: solid 2px #404041;

    opacity: 1;
    transition: all 400ms ease-in-out;
}

.application-result.hide {
    display: none;
    opacity: 0;
    transform: translateY(calc(-70% - 42px)) translateX(-50%);
    transition: all 400ms ease-in-out;
}


.application-result img {
    margin: 16px auto;
    max-width: 80px;
}
.application-result .text-cont {
    margin-top: 12px;
}
.application-result .text-cont p {
    text-align: center;
}
.application-result .text-cont p.app-state {
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-size: 20px;
    font-weight: bold;
    line-height: 1.25em;
    color: #379144;
    /* max-width: 75%; */
    margin: 0 auto;
}

.application-result.error .text-cont p.app-state {
    color: #D9415A;
}

.application-result .text-cont p.app-desc {
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 36px;
}
.application-result button {
    margin: auto;
    margin-bottom: 8px;
    /* font-family: 'Montserrat'; */
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500;
    color: black;
    border: solid 1px #404041;
    background-color: transparent;
    min-width: 140px;
    padding: 4px;
    cursor: pointer;
}
.application-result button:hover {
    background-color: #404041;
    color: #fff;
}

@media (max-width: 576px) {
    .form-modal {
        max-width: 92%;
    }
    .form-modal .modal-body .input-group {
        flex-direction: column;
        align-items: start;
        margin-bottom: 8px;
    }
    .form-modal.show {
        transform: translateX(-50%) translateY(-42%);
    }
}
@media (max-width: 375.98px) {
    .form-modal.show {
        transform: translateX(-50%) translateY(-40%);
    }
}
@media (max-width: 320.98px) {
    .form-modal {
        padding: 8px 12px;
        padding-bottom: 16px;
    }
    .form-modal .modal-logo {
        display: none;
    }
    .form-modal .modal-body {
        max-height: 240px;
        overflow-y: auto;
    }
    .form-modal .modal-body .input-group {
        margin-bottom: 4px;
    }
}