.content.about .section.dream {
    position: relative;
    display: flex;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/section-dream/section-dream-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 480px;
    /* max-height: 720px; */

    overflow: hidden;
}

.content.about .section.dream img.woman-img {
    z-index: 52;
    position: absolute;
    bottom: -60px;
    left: 50%;
    max-width: 360px;
    transform: translateX(-56%);
}
.content.about .section.dream img.circle-img {
    z-index: 51;
    position: absolute;
    top: -60px;
    right: 50%;
    max-width: 720px;
    transform: translateX(50%);
}

.content.about .section.dream .text-content {
    z-index: 60;
    width: 100%;
    max-width: 600px;
    padding-top: 24px;
    margin: 0 auto;
}
.content.about .section.dream .text-content h2 {
    font-size: 20px;
    text-align: center;
    color: #379144;
    margin: 0;
    margin-bottom: 16px;
}
.content.about .section.dream .text-content p {
    font-size: 14px;
    text-align: justify;
    text-align-last: center;
    max-width: 85%;
    margin: 0 auto;
}

@media (min-width: 375px) {
    .content.about .section.dream {
        min-height: 520px;
    }
    .content.about .section.dream img.woman-img {
        max-width: 400px;
        transform: translateX(-54%);
    }
    .content.about .section.dream .text-content h2 {
        font-size: 24px;
    }
    .content.about .section.dream .text-content p {
        font-size: 15px;
    }
    .content.about .section.dream img.circle-img {
        transform: translateX(42%);
    }
}
@media (min-width: 576px) {
    .content.about .section.dream img.woman-img {
        max-width: 480px;
    }
    .content.about .section.dream img.circle-img {
        transform: translateX(0);
        right: 0;
        max-width: 800px;
    }
}
@media (min-width: 768px) {
    .content.about .section.dream {
        min-height: 540px;
    }
    .content.about .section.dream img.circle-img {
        transform: translateX(0);
        right: 0;
        max-width: 720px;
    }
    .content.about .section.dream .text-content {
        margin-right: 42px;
        max-width: 480px;
    }
    .content.about .section.dream .text-content h2 {
        font-size: 28px;
        text-align: right;
        margin-bottom: 12px;
    }
    .content.about .section.dream .text-content p {
        max-width: none;
        text-align: right;
        text-align-last: right;
        margin-bottom: 12px;
    }
    .content.about .section.dream img.woman-img {
        left: 32%;
        max-width: 540px;
    }
}
@media (min-width: 1024px) {
    .content.about .section.dream img.woman-img {
        max-width: 640px;
        left: 28%;
    }
    .content.about .section.dream .text-content {
        max-width: 540px;
    }
    .content.about .section.dream .text-content h2 {
        max-width: 420px;
        margin-right: 0;
        margin-left: auto;
    }
    .content.about .section.dream .text-content p {
        font-size: 16px;
    }
    .content.about .section.dream img.circle-img {
        max-width: 800px;
        right: -24px;
    }
}
@media (min-width: 1440px) {
    .content.about .section.dream .text-content {
        margin-right: 72px;
        margin-top: 24px;
    }
    .content.about .section.dream img.circle-img {
        max-width: 900px;
    }
    .content.about .section.dream .text-content h2 {
        font-size: 32px;
    }
    .content.about .section.dream .text-content p {
        font-size: 18px;
    }
    .content.about .section.dream img.woman-img {
        max-width: 760px;
        left: 30%;
    }
}
@media (min-width: 1900px) {
    .content.about .section.dream {
        min-height: 640px;
    }
    .content.about .section.dream .text-content {
        margin-right: 6%;
        margin-top: 48px;
    }
    .content.about .section.dream img.circle-img {
        max-width: 1200px;
        transform: translateY(-10%);
    }
    .content.about .section.dream .text-content h2 {
        font-size: 36px;
        max-width: none;
    }
    .content.about .section.dream .text-content {
        max-width: 600px;
    }
    .content.about .section.dream img.woman-img {
        max-width: 900px;
    }
}