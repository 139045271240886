
.content.about {
    max-width: 1920px;
    margin: auto;
}
.content.about main {
    position: relative;
    display: flex;
    align-items: flex-start;

    height: inherit;
    max-height: 100%;
    /* min-height: 420px; */
    width: 100%;
    box-sizing: border-box;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/banner-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    overflow: hidden;
}

.content.about main h1 {
    z-index: 60;
    position: relative;
    margin: auto;

    font-size: 20px;
    /* font-family: 'Montserrat'; */
    font-family: 'Lato';
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;

    line-height: 2em;

    color: #404041;
    transform: translateY(-30%);
    opacity: 0;

    /* background-image: linear-gradient(to right, #379144,#379144, transparent, transparent); */
    /* background-size: 400% 100%;
    background-position: right center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    transition: all 400ms ease-out;
}
.content.about main h1 span {
    font-family: 'Feel-free';
    font-size: 3em;
    text-transform: none;
    color: #379144;
}
.content.about main h1 span:nth-child(3) {
    color: #404041;
}
.content.about main.show h1 {
    /* background-position: left center; */
    opacity: 1;
    transform: translateY(-16%);
    transition: all 1s ease-in-out 2.5s;
}


.content.about main .banner-img {
    z-index: 50;
    position: absolute;
    width: 100%;
    max-width: 720px;
    opacity: .24;
    transition: all 1200ms ease-out;
}
.content.about main.show .banner-img {
    transition: all 1400ms ease-in-out 420ms;
}
.content.about main .banner-img.img-1 {
    z-index: 57;
    top: -10%;
    left: -20%;
    max-width: 320px;
    transform: translateY(-42%) translateX(-72%);
}

.content.about main .banner-img.img-2 {
    z-index: 55;
    top: -16%;
    left: 50%;
    max-width: 400px;
    transform: translateX(-50%) translateY(-24%);
}
.content.about main .banner-img.img-3 {
    z-index: 57;
    top: -10%;
    right: -20%;
    max-width: 240px;
    transform: translateY(-20%) translateX(54%);
}
.content.about main .banner-img.img-4 {
    z-index: 57;
    bottom: -10%;
    left: -20%;
    max-width: 400px;
    transform: translateY(0) translateX(-72%);
}
.content.about main .banner-img.img-5 {
    z-index: 55;
    bottom:-16%;
    left: 50%;
    max-width: 320px;
    transform: translateX(-40%) translateY(28%);
}
.content.about main .banner-img.img-6 {
    z-index: 57;
    bottom: -10%;
    right: -20%;
    max-width: 240px;
    transform: translateY(20%) translateX(50%);
}


.content.about main.show .banner-img.img-1 {
    top: 0;
    left: 0;
    opacity: 1;
}
.content.about main.show .banner-img.img-2 {
    top: 0;
    left: 50%;
    opacity: 1;
}
.content.about main.show .banner-img.img-3 {
    top: 0;
    right: 0;
    opacity: 1;
}
.content.about main.show .banner-img.img-4 {
    bottom: 0;
    left: 0;
    opacity: 1;
}
.content.about main.show .banner-img.img-5 {
    z-index: 55;
    bottom: 0;
    left: 50%;
    opacity: 1;
}
.content.about main.show .banner-img.img-6 {
    bottom: 0;
    right: 0;
    opacity: 1;
}

.content.about .section {
    min-height: 420px;
}

@media (min-width: 360px) {
    .content.about main h1 {
        font-size: 24px;
        transform: translateY(-60%);
    }
    .content.about main.show h1 {
        opacity: 1;
        transform: translateY(-24%);
    }
    .content.about main .banner-img.img-1 {
        z-index: 56;
        max-width: 600px;
        left: -20%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-32%) translateX(-72%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 640px;
        transform: translateX(-50%) translateY(-12%);
    }
    .content.about main.show .banner-img.img-2 {
        top: 0;
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 540px;
        transform: translateY(-20%) translateX(64%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 360px;
        min-width: 500px;
        transform: translateX(-80%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 580px;
        min-width: 480px;
        transform: translateX(-36%) translateY(28%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 300px;
        transform: translateY(8%) translateX(48%);
    }
}
@media (min-width: 390px) {
    .content.about main h1 {
        font-size: 28px;
        /* transform: translateY(-32%); */
    }
}
@media (min-width: 414px) {
    .content.about main {
        max-height: 600px;
    }
    .content.about main h1 {
        /* font-size: 54px; */
        transform: translateY(-32%);
    }
}
@media (min-width: 576px) {
    .content.about main {
        max-height: 600px;
    }
    .content.about main h1 {
        /* font-size: 54px;
        transform: translateY(-20%); */
    }
    .content.about main .banner-img.img-1 {
        z-index: 56;
        max-width: 540px;
        left: -20%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-42%) translateX(-72%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 500px;
        transform: translateX(-50%) translateY(-12%);
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 500px;
    }
    .content.about main .banner-img.img-4 {
        max-width: 360px;
        min-width: 540px;
        transform: translateX(-72%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 520px;
        min-width: 420px;
        transform: translateX(-36%) translateY(28%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 320px;
        transform: translateY(8%) translateX(48%);
    }
}
@media (min-width: 768px) {
    .content.about main {
        min-height: 640px;
        max-height: 640px;
    }
    .content.about main h1 {
        font-size: 36px;
        text-align: center;
        transform: translateY(-28%);
    }
    .content.about main .banner-img.img-1 {
        z-index: 56;
        max-width: 540px;
        left: -62%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-36%) translateX(-68%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 640px;
        transform: translateX(-50%) translateY(-40%);
    }
    .content.about main.show .banner-img.img-2 {
        top: 0;
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 540px;
        transform: translateY(-20%) translateX(60%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 640px;
        transform: translateX(-72%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 580px;
        transform: translateX(-52%) translateY(28%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 360px;
        transform: translateY(10%) translateX(32%);
    }
}
@media (min-width: 1024px) {
    .content.about main { 
        min-height: 600px;
    }
    .content.about main h1 {
        font-size: 36px;
        transform: translateY(-58%);
    }
    .content.about main .banner-img.img-1 {
        max-width: 50%;
        left: -32%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-36%) translateX(-48%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 640px;
        transform: translateX(-50%) translateY(-36%);
    }
    .content.about main.show .banner-img.img-2 {
        top: 0;
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 50%;
        transform: translateY(-24%) translateX(40%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 64%;
        transform: translateX(-56%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 640px;
    }
    .content.about main .banner-img.img-6 {
        max-width: 42%;
        transform: translateY(12%) translateX(24%);
    }
}
@media (min-width: 1280px) {
    .content.about main { 
        min-height: 600px;
    }
    .content.about main h1 {
        /* font-size: 100px; */
        transform: translateY(-50%);
    }
    .content.about main .banner-img.img-1 {
        max-width: 48%;
        left: -32%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-48%) translateX(-48%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 740px;
        transform: translateX(-50%) translateY(-44%);
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 44%;
        transform: translateY(-28%) translateX(36%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 54%;
        transform: translateX(-50%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 720px;
        transform: translateX(-52%) translateY(42%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 36%;
    }
}
@media (min-width: 1440px) {
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-48%) translateX(-42%);
    }
    .content.about main .banner-img.img-1 {
        max-width: 36%;
        left: -20%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-44%) translateX(-16%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 720px;
        transform: translateY(-42%) translateX(-50%);
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 32%;
        transform: translateY(-24%) translateX(0%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 48%;
        transform: translateX(-36%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 740px;
        transform: translateX(-50%) translateY(42%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 32%;
        transform: translateY(16%) translateX(0%);
    }
}
@media (min-width: 1600px) {
    .content.about main {
        min-height: 640px;
        max-height: 100%;
    }
    .content.about main h1 {
        transform: translateY(-72%);
    }
    .content.about main .banner-img.img-1 {
        max-width: 36%;
        left: -20%;
    }
    .content.about main.show .banner-img.img-1 {
        transform: translateY(-44%) translateX(-16%);
    }
    .content.about main .banner-img.img-2 {
        max-width: 720px;
        transform: translateY(-42%) translateX(-50%);
    }
    
    .content.about main .banner-img.img-3 {
        max-width: 32%;
        transform: translateY(-24%) translateX(0%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 48%;
        transform: translateX(-36%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 740px;
        transform: translateX(-50%) translateY(32%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 32%;
        transform: translateY(16%) translateX(0%);
    }
    .content.about main.show h1 {
        transform: translateY(-42%);
        transition: all 1s ease-in-out 2.5s;
    }
}
@media (min-width: 1920px) {
    .content.about main .banner-img.img-1 {
        max-width: 720px;
    }
    .content.about main .banner-img.img-2 {
        max-width: 720px;
        transform: translateY(-32%) translateX(-50%);
    }
    .content.about main .banner-img.img-3 {
        max-width: 640px;
        transform: translateY(-28%) translateX(0%);
    }
    .content.about main .banner-img.img-4 {
        max-width: 840px;
        transform: translateX(-28%);
    }
    .content.about main .banner-img.img-5 {
        max-width: 800px;
        transform: translateX(-50%) translateY(28%);
    }
    .content.about main .banner-img.img-6 {
        max-width: 640px;
        transform: translateY(20%) translateX(0%);
    }
   .content.about main {
        min-height: 800px;
        max-height: 800px;
    }
}