.editionScene {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  max-width: 1024px;
  margin-top: 40px;
}

.editionScene .card {
  position: relative;
  width: 270px;
  height: 350px;
  color: white;
  cursor: pointer;
  transition: 800ms ease-in-out;
  transform-style: preserve-3d;
  border-radius: 16px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.editionScene .card:hover {
  transform: rotateY(0.5turn);
}

.editionScene .card__face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: 800ms ease-in-out;
}

.editionScene .card__face img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.editionScene .img-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
}
.editionScene .card__face--back {
  transform: rotateY(0.5turn);
  background-color: white;
  border-radius: 16px;
}
.editionScene .card__face--back img {
  opacity: 0.2;
}
.editionScene .card-title {
  position: absolute;
  font-family: "Lato";
  bottom: 0;
  margin: 14px;
  font-size: 28px;
  font-weight: 500;
  z-index: 10;
  text-align: start;
}
.editionScene .editionSpecification {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  color: black;
}
.editionScene .editionSpecification b {
  margin-bottom: 12px;
  font-family: "Lato";
  font-size: 20px;
  color: #003262;
}
.editionScene .editionSpecification div {
  font-family: "Lato";
  font-size: 18px;
}
.editionScene .editionSpecification a {
  margin-top: auto;
  color: #003262;
  background-color: white;
  text-decoration: none;
  padding: 8px 24px;
  margin-top: 40px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  transition: box-shadow 500ms ease 0s;
}
.editionScene .editionSpecification a:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
@media (max-width: 768px) {
  .editionScene {
    display: none;
  }
}
