@font-face {
    font-family: 'corporate';
    src: url('../../../fonts/corporate-coming-soon-font.ttf');
}
@font-face {
    font-family: 'Lato Regular';
    src: url('../../../fonts/Lato-Regular.ttf');
}
@font-face {
    font-family: 'Lato Light';
    src: url('../../../fonts/Lato-Light.ttf');
}
@font-face {
    font-family: 'Lato Bold';
    src: url('../../../fonts/Lato-Bold.ttf');
}
@font-face {
    font-family: 'Lato Thin';
    src: url('../../../fonts/Lato-Thin.ttf');
}

.coming-soon {
    position: fixed;
    display: flex;
    min-height: 100vh;
    max-height: 100%;
    width: 100vw;
    max-width: 100%;
    box-sizing: border-box;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/coming-soon/coming-soon-bg.jpg');
    background-color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    overflow-x: hidden;
    overflow-y: auto;

}

.coming-soon * {
    font-family: 'Lato Regular';
}
.coming-soon .text-content {
    position: relative;
    z-index: 40;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px 16px;
    max-width: 90%;

    transform: translateY(36px);

    border-radius: 6px;
    box-sizing: border-box;
    /* background-color: rgba(250, 250, 250, .92); */
}
.coming-soon .text-content .header-title {
    font-size: 20px;
    font-family: 'Lato Light';
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5em;

    color: #404041;
    margin: 0;
}
.coming-soon .text-content .header-title span {
    font-family: 'corporate';
    font-size: 2.32em;
    text-transform: lowercase;
    color: #329347;
}
.coming-soon .text-content .header-title span:first-child {
    margin-right: 3px;
}

.coming-soon .text-content p {
    font-size: 15px;
    text-align: center;

    color: #4a4a4a;
}
.coming-soon .text-content small {
    font-size: 12px;
    font-style: italic;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
    color: #4a4a4a;
}

.coming-soon .text-content form > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.coming-soon .text-content form input {
    font-size: 18px;
    text-align: center;

    letter-spacing: 0.02em;
    width: 100%;

    padding: 4px 6px;
    border: solid 2px #4a4a4a;
    border-radius: 42px;

    outline: none;
    margin-bottom: 12px;
}
.coming-soon .text-content form button {
    font-size: 18px;
    font-family: 'Lato Bold';
    text-transform: uppercase;
    color: #fff;
    
    min-width: 140px;
    height: 36px;
    
    border-radius: 48px;
    border: solid 2px #329347;
    background-color: #329347;

    outline: none;

    cursor: pointer;

    transition: all 240ms ease-in-out;
}
.coming-soon .text-content form button:disabled {
    pointer-events: none;
    background-color: #404041;
    border-color: #404041;
    color: #e2e2e2;
}
.coming-soon .text-content form button:focus,
.coming-soon .text-content form button:active,
.coming-soon .text-content form button:hover {
    color: #329347;
    background-color: transparent;
}

.coming-soon img.imgs {
    position: fixed;
    align-self: flex-start;
    width: 100%;
    max-width: 200px;
}

.coming-soon img.imgs#img6 {
    z-index: 19;
    top: 0;
    left: 0;
    transform: translateX(-64%) translateY(-45%);
    max-width: 200px;
}
.coming-soon img.imgs#img1 {
    z-index: 20;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-32%);
}
.coming-soon img.imgs#img2 {
    z-index: 19;
    top: 0;
    right: 0;
    transform: translateX(70%) translateY(-42%);
    max-width: 240px;
}


.coming-soon img.imgs#img3 {
    z-index: 19;
    bottom: 0;
    right: 0;
    transform: translateX(55%) translateY(24%);
}
.coming-soon img.imgs#img4 {
    z-index: 20;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(15%);
}
.coming-soon img.imgs#img5 {
    z-index: 19;
    bottom: 0;
    left: 0;
    transform: translateX(-55%) translateY(15%);
    max-width: 240px;
}

.coming-soon .recaptcha {
    margin: auto;
    margin-top: 16px;

    transform: translateX(-24px);
}
@media (min-width: 360px) {
    .coming-soon .text-content {
        transform: translateY(0);
    }
    .coming-soon .recaptcha {
        transform: translateX(-8px);
    }
}
@media (min-width: 375px) {
    .coming-soon .text-content .header-title {
        font-size: 24px;
        margin-bottom: 8px;
    }
    .coming-soon .text-content {
        background-color: transparent;
    }
    .coming-soon img.imgs#img1 {
        max-width: 320px;
    }
    .coming-soon img.imgs#img2 {
        max-width: 320px;
        top: 12%;
    }
    .coming-soon img.imgs#img6 {
        max-width: 240px;
        top: 10%;
    }
    .coming-soon img.imgs#img3 {
        max-width: 240px;
        bottom: 10%;
    }
    .coming-soon img.imgs#img4 {
        max-width: 320px;
    }
    .coming-soon img.imgs#img5 {
        max-width: 320px;
        bottom: 6%;
    }
    .coming-soon .recaptcha {
        transform: translateX(0);
    }
}
@media (min-width: 414px) {
    .coming-soon img.imgs#img1 {
        transform: translateX(-50%) translateY(-10%);
    }
}
@media (min-width: 768px) {
    .coming-soon img.imgs#img1 {
        transform: translateX(-50%) translateY(-24%);
    }
    .coming-soon .text-content {
        max-width: 600px;
    }
    .coming-soon .text-content .header-title {
        font-size: 42px;
    }
    .coming-soon .text-content p {
        font-size: 20px;
    }
    .coming-soon .text-content small {
        font-size: 16px;
    }
    .coming-soon .text-content form {
        width: 100%;
        max-width: 320px;
        margin-top: 20px;
    }
    .coming-soon .text-content form button {
        min-width: 200px;
    }
    .coming-soon .text-content form > div {
        width: 100%;
    }
    .coming-soon img.imgs#img1 {
        max-width: 540px;
    }
    .coming-soon img.imgs#img2 {
        max-width: 540px;
        transform: translateX(64%) translateY(-42%);
    }
    .coming-soon img.imgs#img6 {
        max-width: 480px;
        transform: translateX(-56%) translateY(-54%);
    }
    .coming-soon img.imgs#img3 {
        max-width: 480px;
    }
    .coming-soon img.imgs#img4 {
        max-width: 600px;
    }
    .coming-soon img.imgs#img5 {
        max-width: 640px;
        transform: translateX(-60%) translateY(10%);
    }
}

@media (min-width: 1024px) and (max-height: 768px) {
    .coming-soon .text-content {
        border: none;
        background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/coming-soon/white-bg.png');
        background-color: transparent;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        transform: translateY(12px);
    }
    .coming-soon img.imgs#img1 {
        max-width: 600px;
        transform: translateX(-50%) translateY(-50%);
    }
    .coming-soon img.imgs#img2 {
        max-width: 600px;
        transform: translateX(60%) translateY(-36%);
    }
    .coming-soon img.imgs#img6 {
        max-width: 420px;
        transform: translateX(-42%) translateY(-32%);
    }
    .coming-soon img.imgs#img3 {
        max-width: 480px;
        transform: translateX(42%) translateY(28%);
    }
    .coming-soon img.imgs#img4 {
        max-width: 540px;
        transform: translateX(-50%) translateY(20%);
    }
    .coming-soon img.imgs#img5 {
        max-width: 600px;
        transform: translateX(-46%) translateY(20%);
    }
}

@media (min-width: 1280px) {
    .coming-soon img.imgs#img1 {
        max-width: 600px;
        transform: translateX(-50%) translateY(-40%);
    }
    .coming-soon img.imgs#img2 {
        transform: translateX(36%) translateY(-30%);
    }
    .coming-soon img.imgs#img6 {
        max-width: 480px;
        transform: translateX(-24%) translateY(-24%);
    }
    .coming-soon img.imgs#img3 {
        max-width: 480px;
        transform: translateX(20%) translateY(20%);
    }
    .coming-soon img.imgs#img4 {
        max-width: 640px;
        transform: translateX(-50%) translateY(25%);
    }
    .coming-soon img.imgs#img5 {
        max-width: 600px;
        transform: translateX(-28%) translateY(4%);
    }
}
@media (min-width: 1366px) {
    .coming-soon img.imgs#img1 {
        max-width: 640px;
    }
    .coming-soon img.imgs#img6 {
        transform: translateX(-18%) translateY(-24%);
    }
    .coming-soon img.imgs#img2 {
        transform: translateX(32%) translateY(-30%);
    }
}
@media (min-width: 1440px) {
    .coming-soon img.imgs#img1 {
        max-width: 700px;
        transform: translateX(-50%) translateY(-44%);
    }
    .coming-soon img.imgs#img6 {
        transform: translateX(-14%) translateY(-32%);
    }
    .coming-soon img.imgs#img3 {
        transform: translateX(14%) translateY(20%);
    }
    .coming-soon img.imgs#img4 {
        max-width: 700px;
    }
    .coming-soon img.imgs#img5 {
        max-width: 640px;
        transform: translateX(-28%) translateY(12%);
    }
}

@media (min-width: 1600px) {
    .coming-soon img.imgs#img1 {
        max-width: 740px;
        transform: translateX(-50%) translateY(-45%);
    }
    .coming-soon img.imgs#img2 {
        max-width: 600px;
        transform: translateX(20%) translateY(-42%);
    }
    .coming-soon img.imgs#img6 {
        max-width: 560px;
        transform: translateX(-18%) translateY(-42%);
    }
    .coming-soon img.imgs#img3 {
        max-width: 540px;
        transform: translateX(12%) translateY(24%);
    }
    .coming-soon img.imgs#img4 {
        max-width: 720px;
        transform: translateX(-50%) translateY(25%);
    }
    .coming-soon img.imgs#img5 {
        max-width: 640px;
        transform: translateX(-15%) translateY(12%);
    }
    .coming-soon .text-content form {
        max-width: unset;
    }
    .coming-soon .text-content form > div {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .coming-soon .text-content form input {
        text-align: left;
        padding: 5px 16px;
        margin-bottom: 0;
        max-width: 280px;
    }
    .coming-soon .text-content form button {
        min-width: 160px;
        margin-left: 12px;
    }
    .coming-soon .text-content {
        max-width: 720px;
    }
    .coming-soon .text-content .header-title {
        font-size: 54px;
        line-height: 1.25em;
    }
}
@media (min-width: 1920px) {
    .coming-soon img.imgs#img1 {
        max-width: 840px;
        transform: translateX(-50%) translateY(-40%);
    }
    .coming-soon img.imgs#img2 {
        max-width: 640px;
        transform: translateX(8%) translateY(-30%);
    }
    .coming-soon img.imgs#img6 {
        max-width: 640px;
        transform: translateX(-10%) translateY(-40%);
    }
    .coming-soon img.imgs#img3 {
        max-width: 600px;
        transform: translateX(4%) translateY(24%);
    }
    .coming-soon img.imgs#img4 {
        max-width: 840px;
        transform: translateX(-50%) translateY(28%);
    }
    .coming-soon img.imgs#img5 {
        max-width: 720px;
        transform: translateX(-12%) translateY(10%);
    }
    
    .coming-soon .text-content {
        max-width: 800px;
    }
    .coming-soon .text-content .header-title {
        /* font-size: 72px; */
    }
}

.success-modal,
.error-modal {
    z-index: 1000;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-40px);

    width: 100%;
    max-width: 360px;
    min-height: 120px;

    padding: 12px;

    border-radius: 8px;
    box-sizing: border-box;
    background-color: #329347;

    box-shadow: 0 0 8px rgba(255, 255, 255, .9);
    transition: all 400ms ease-in-out;

    opacity: 0;
}
.success-modal.show {
    opacity: 1;
    top: 10%;
}
.error-modal {
    background-color: #E04C5E;
    max-width: 400px;
    min-height: 80px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .9);
}
.error-modal.show {
    opacity: 1;
    top: 12%;
}
.success-modal .modal-header,
.error-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    /* padding-bottom: 8px; */
    /* border-bottom: solid 2px #fff; */
    box-sizing: border-box;
}
.success-modal .modal-header i,
.error-modal .modal-header i {
    color: #fff;
    cursor: pointer;
}
.success-modal .modal-header h5,
.error-modal .modal-header h5 {
    font-size: 16px;
    font-family: 'Lato Thin';
    text-transform: uppercase;

    color: #fff;
    margin: 0;
}
.success-modal .modal-body,
.error-modal .modal-body {
    width: 100%;
    margin: auto;
}
.success-modal .modal-body p,
.error-modal .modal-body p {
    font-size: 42px;
    font-family: 'corporate';
    text-align: center;

    color: #329347;
    color: #fff;
    margin: 0;
}
.error-modal .modal-body p {
    font-family: 'Lato Regular';
    font-size: 18px;
}