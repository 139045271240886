.content.pphi div.banner {
  position: relative;
  display: flex;
  width: 100%;

  min-height: 420px;
  box-sizing: border-box;

  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.content.pphi div.banner .color-overlay {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, .6); */
  /* background-image: linear-gradient(0deg, rgba(25,25,25,.8) 48%, transparent); */
  /* background-image: linear-gradient(0deg, rgba(200,200,200,.7) 48%, transparent); */
  background-image: linear-gradient(
    0deg,
    rgba(250, 250, 250, 0.85) 48%,
    transparent
  );
}
.content.pphi div.banner .text-content {
  z-index: 300;
  color: #fff;
  width: 100%;
  max-width: 90%;
  margin: auto;
  text-align: center;
}
.content.pphi div.banner .text-content h1 {
  font-family: "lato";
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
}
.content.pphi div.banner .text-content h1 span {
  font-family: "lato";
  font-weight: 400;
  font-size: 1.2em;
}
.content.pphi div.banner .text-content p {
  /* font-size: 16px; */
  font-size: 14px;
  /* text-align-last: left; */
  line-height: 1.25em;
  margin: 0;

  color: #404041;
}

.properties {
  position: relative;
  width: 100%;
  padding: 40px 0;
  text-align: center;
  /* background-image: linear-gradient(
      to bottom,
      white,
      rgba(255, 255, 255, 0) 20%
    ),
    url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/textured-bg.jpg"); */
  background-size: 100%;
  background-position: center;
  background-repeat: repeat-y;
}

.content.pphi div.video-embed {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 42px 0 0 0;
  box-sizing: border-box;

  background-color: #f5f5f5;
}
.content.pphi div.video-embed iframe {
  aspect-ratio: 16/9;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.content.pphi div.about-pphi {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  justify-content: center;
  box-sizing: border-box;
  background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/New%20PPHI.jpg"),
    linear-gradient(rgba(250, 250, 250, 0.7), rgba(250, 250, 250, 0.7));
  background-blend-mode: overlay;
}
.content.pphi div.about-pphi .text-content {
  width: 100%;
  max-width: 85%;
  padding: 24px 24px;
}
.content.pphi div.about-pphi .text-content h5 {
  font-size: 14px;
  /* text-align: left; */
  /* color: #4b80a9; */
  color: #ffffff;
  margin: 0;
  font-style: italic;
  text-align: center;
}
.content.pphi div.about-pphi .text-content h2 {
  font-size: 28px;
  /* font-family: 'LT Bulletin'; */
  text-transform: uppercase;
  /* letter-spacing: 1px; */
  /* color: #e5a724; */
  margin: 0;
  text-align: center;
}
.content.pphi div.about-pphi .text-content p {
  text-align: center;
  margin-bottom: 16px;
  color: black;
  line-height: 1.25em;
}

.header-img-pphi {
  /* width: 250px; */
  width: 253px;
  /* width: 400px; */
  /* width: 50% !important; */
  /* margin-left: -20px; */
  /* text-align: center; */
  /* margin-bottom: 20px; */
  /* margin-left: auto; */
  margin-left: 4px;
  margin-right: auto;
  /* margin-top: 150px; */
}

@media only screen and (max-width: 600px) {
  .header-img-pphi {
    margin-top: 150px;
  }
}

@media (min-width: 1023px) {
  .content.pphi div.about-pphi {
    align-items: start;
  }
  .content.pphi div.about-pphi .text-content {
    padding: 24px 100px;
    max-width: 600px;
  }
  .content.pphi div.banner .text-content {
    max-width: 840px;
  }
  .content.pphi div.banner .text-content h1 {
    font-size: 42px;
  }
  .content.pphi div.banner .text-content p {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .content.pphi div.about-pphi {
    align-items: start;
  }
  .content.pphi div.about-pphi .text-content {
    padding: 24px 140px;
    max-width: 600px;
  }

  .content.pphi div.about-pphi .text-content h2 {
    font-size: 36px;
  }
  .content.pphi div.about-pphi {
    /* background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/about-sights-bg-desktop.jpg'); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
  }
  .content.pphi div.banner {
    background-attachment: fixed;
    /* background-position: 100% 60%; */
    background-position: 100% 100%;
  }
}
@media (min-width: 1600px) {
  .content.pphi div.about-pphi {
    align-items: start;
  }
  .content.pphi div.about-pphi .text-content {
    padding: 24px 160px;
    max-width: 1000px;
  }

  .content.pphi div.about-pphi {
    min-height: 540px;
  }
  .content.pphi div.about-pphi .text-content p {
    font-size: 18px;
  }
  .content.pphi div.banner {
    min-height: 600px;
  }
  .content.pphi div.banner .text-content p {
    font-size: 20px;
  }
  .content.pphi div.banner .text-content h1 {
    font-size: 54px;
  }
}
@media (min-width: 1900px) {
  .content.pphi div.about-pphi {
    align-items: start;
  }
  .content.pphi div.about-pphi .text-content {
    padding: 24px 200px;
    max-width: 1000px;
  }
  .content.pphi div.banner .text-content h1 {
    font-size: 60px;
  }
}

.pphiBtn {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #379144;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  max-width: 17rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin: 32px auto;
}

.pphi__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: #379144;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.pphiBtn:hover {
  background-color: white;
  color: #379144;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.pphi__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.pphiBtn:hover .pphi__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.pphiBtn:hover .pphi__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

.pphiPillar {
  position: relative;
  height: 95vh;
  max-height: 1080px;
  background-image: linear-gradient(
      to bottom,
      white,
      rgba(255, 255, 255, 0) 50%
    ),
    url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/pillar/pphi.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.pphiPillar-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 82px 16px;
  z-index: 2;
}
.pphiPillar-content p {
  font-family: "Didot" !important;
  font-size: 1.5rem;
  line-height: 0%;
  color: black;
  margin-top: 16px;
}
.pphiWrapper-pillar {
  position: relative;
}

.pphiWrapper-pillar .pphiMain-text,
.pphiAnimation-pillar {
  font-family: "Didot" !important;
  font-size: 3rem;
  line-height: 0%;
  color: #379144;
}
.pphiWrapper-pillar .pphiMain-text {
  opacity: 0;
  padding: 0 6px;
}
.pphiAnimation-pillar {
  position: absolute;
  top: -1.2rem;
  height: auto;
  overflow: hidden;
  line-height: normal;
  width: auto;
  text-align: start;
  margin: 0 6px;
}
@media (max-width: 1024px) {
  .pphiPillar {
    height: 70vh;
  }
  .pphiPillar-content p {
    font-size: 1rem;
  }
  .pphiWrapper-pillar .pphiMain-text,
  .pphiAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 2rem;
    line-height: 0%;
    color: #379144;
  }
  .pphiAnimation-pillar {
    position: absolute;
    top: -0.7rem;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
    margin: 0 6px;
  }
}
@media (max-width: 768px) {
  .pphiPillar-content {
    padding: 68px 16px;
  }
  .pphiPillar-content p {
    font-size: 1rem;
    margin-top: 12px;
  }
  .pphiWrapper-pillar .pphiMain-text,
  .pphiAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 1.7rem;
    line-height: 0%;
    color: #379144;
  }
  .pphiAnimation-pillar {
    position: absolute;
    top: -0.4rem;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
  }
}
@media (max-width: 640px) {
  .pphiPillar {
    height: 40vh;
  }
  .pphiPillar-content {
    padding: 32px 0;
  }
  .pphiPillar-content p {
    font-size: 12px;
    margin-top: 4px;
  }
  .pphiWrapper-pillar .pphiMain-text,
  .pphiAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 15px;
    line-height: 0%;
    color: #379144;
  }
  .pphiWrapper-pillar .pphiMain-text {
    opacity: 0;
    padding: 0 2px;
  }
  .pphiAnimation-pillar {
    position: absolute;
    top: -2px;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
    margin: 0 2px;
  }
}
@media (max-width: 320px) {
  .pphiPillar {
    height: 35vh;
  }

  .pphiPillar-content p {
    font-size: 9px;
    margin-top: 4px;
  }
  .pphiWrapper-pillar .pphiMain-text,
  .pphiAnimation-pillar {
    font-family: "Didot" !important;
    font-size: 14px;
    line-height: 0%;
    color: #379144;
  }
  .pphiAnimation-pillar {
    position: absolute;
    top: -3px;
    height: auto;
    overflow: hidden;
    line-height: normal;
    width: auto;
    text-align: center;
  }
}
