.content.about .section.intro-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 0;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/mobile-bg-intro.jpg');
    background-size: cover;
    background-repeat: repeat-y;
}
.content.about .section.intro-mobile .text-content {
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 24px;
}
.content.about .section.intro-mobile .text-content h2{
    font-size: 24px;
    text-align: center;
    color: #379144;

    margin: 0;
}
.content.about .section.intro-mobile .text-content p {
    text-align: justify;
    text-align-last: center;
}
.content.about .section.intro {
    position: relative;
    display: none;
    align-items: stretch;
    
    min-height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: all 720ms ease-in-out 200ms;
}
.content.about .section.intro .text-content {
    display: flex;
    width: 100%;
    min-width: 1000px;
    max-width: 1200px;

    background-image: linear-gradient(to right, #fff 60%, transparent);
}

.content.about .section.intro .text-content > div {
    margin: auto;
    margin-left: 140px;
    max-width: 70%;
}
.content.about .section.intro .text-content > div h2 {
    font-size: 42px;
    color: #379144;
    margin: 0;
    margin-bottom: 16px;
    max-width: 80%;
}
.content.about .section.intro .text-content > div p {
    font-size: 20px;
    line-height: 1.25em;
    margin: 0;
}
.content.about .section.intro-mobile .cards-container-mobile,
.content.about .section.intro .cards-container {
    position: relative;
    display: flex;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile {
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;


    width: 100%;

}
.content.about .section.intro .cards-container .card-slide {
    position: relative;
    margin: auto;
    margin-left: auto;
    margin-right: 0;

    display: flex;
    align-items: center;


    width: 100%;
    max-width: 720px;

    height: 100%;

    overflow-y: hidden;
    overflow-x: hidden;

    scroll-behavior: smooth;
    scroll-padding-left: 12px;

    transform: translateX(100%);
    transition: all 600ms ease-out;
}

.content.about .section.intro.show .cards-container .card-slide {
    transform: translateX(0);
    transition: all 800ms ease-in-out 1s;
}

.content.about .section.intro .cards-container .card-slide .phantom-card {
    display: block;
    min-width: 320px;
    max-width: 320px;
    height: 480px;
    background-color: transparent;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card {
    position: relative;
    display: flex;

    min-width: 240px;
    max-width: 240px;
    height: 340px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border: solid 6px #fff;
    border-radius: 24px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .42);

    overflow: hidden;

    margin: auto;
    margin-bottom: 42px;
    /* margin-right: 42px; */
    /* transform: translateY(-25%); */
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:last-child {
    margin-bottom: 16px;
}
.content.about .section.intro .cards-container .card-slide .card {
    position: relative;
    display: flex;

    min-width: 280px;
    max-width: 280px;
    height: 420px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border: solid 6px #fff;
    border-radius: 24px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .42);

    overflow: hidden;

    margin-right: 42px;
    /* transform: translateY(-25%); */
}
.content.about .section.intro .cards-container .card-slide .card.hide {
    /* transform: translateY(-10%); */
}

.content.about .section.intro .cards-container .card-slide .card.active {
    transform: translateY(0) !important;
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .color-overlay,
.content.about .section.intro .cards-container .card-slide .card .color-overlay {
    z-index: 90;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(25deg, #522d6d, #4197cb);
    opacity: .86;
    box-shadow: 0px 0px 42px inset rgba(25, 25, 25, .4);
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content,
.content.about .section.intro .cards-container .card-slide .card .card-content {
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 12px 0;
    padding-bottom: 8px;
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content *,
.content.about .section.intro .cards-container .card-slide .card .card-content * {
    margin: 0;
    color: #fff;
    /* opacity: 0; */
}

/* .content.about .section.intro .cards-container .card-slide .card.show .card-content * {
    opacity: 1;
    transition: all 600ms ease-in 400ms;
} */
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content h4 {
    font-size: 24px;
    text-align: center;
    
    color: #fff;
    margin: 0;
    margin-bottom: 4px;
}
.content.about .section.intro .cards-container .card-slide .card .card-content h4 {
    display: none !important;
    font-size: 28px;
    text-align: center;
    
    color: #fff;
    margin-bottom: 12px;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(1) .card-content h4 {
    font-family: 'Playfair Display', serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 1em;
    padding: 0 24px;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(1) .card-content h4 {
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    font-weight: normal;
    line-height: 1em;
    padding: 0 24px;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(1) .card-content h4 span {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 1.75em;
}


.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(2) .card-content h4 {
    font-size: 24px;
    font-weight: 500;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(2) .card-content h4 span {
    font-family: 'Optima';
    font-weight: 400;
    font-size: 1.5em;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(2) .card-content h4 {
    font-size: 24px;
    font-weight: 500;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(2) .card-content h4 span {
    font-family: 'Optima';
    font-weight: 400;
    font-size: 1.75em;
}



.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(3) .card-content h4 {
    line-height: 1.15em;
    font-weight: 500;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(3) .card-content h4 span {
    font-family: 'Caveat';
    font-weight: 400;
    font-size: 1.75em;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(3) .card-content h4 {
    line-height: 1.25em;
    font-weight: 500;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(3) .card-content h4 span {
    font-family: 'Caveat';
    font-weight: 400;
    font-size: 1.75em;
}


.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(4) .card-content h4 {
    display: flex;
    align-items: center;
    line-height: 1.25em;
    font-weight: 500;
    margin-bottom: 12px;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(4) .card-content h4 span {
    display: block;
    font-family: 'Manta';
    font-weight: 400;
    font-size: 1.32em;
    line-height: 1.5em;

    margin-left: 6px;
    margin-top: 10px;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(4) .card-content h4 {
    display: flex;
    align-items: center;
    line-height: 1.25em;
    font-weight: 500;
    margin-bottom: 12px;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(4) .card-content h4 span {
    display: block;
    font-family: 'Manta';
    font-weight: 400;
    font-size: 1.32em;
    line-height: 1.5em;

    margin-left: 6px;
    margin-top: 10px;
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content p {
    font-size: 14px;
    text-align: center;
    max-width: 90%;
}
.content.about .section.intro .cards-container .card-slide .card .card-content p {
    font-size: 15px;
    text-align: center;
    max-width: 80%;
    line-height: 1.25em;
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content img.card-logo {
    max-width: 72px;
}
.content.about .section.intro .cards-container .card-slide .card .card-content img.card-logo {
    width: 100%;
    max-width: 140px;
    margin-top: 12px;
    margin-bottom: 8px;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(2) .card-content img.card-logo {
    margin-bottom: 0;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(3) .card-content img.card-logo {
    margin-bottom: 0;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(4) .card-content img.card-logo {
    margin-bottom: 0;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(5) .card-content img.card-logo {
    /* margin-bottom: 0; */
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(5) .card-content p {
    margin-bottom: 12px;
    max-width: 85%;
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content a,
.content.about .section.intro .cards-container .card-slide .card .card-content a {
    margin-top: auto;
    margin-bottom: 8px;

    border: solid 2px #fff;
    border-radius: 8px;
    padding: 8px 16px;

    text-decoration: none;

    cursor: pointer;
}

.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(1) .card-content a {
    background-color: #379144;
    margin-bottom: auto;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(2) .card-content a {
    background-color: #003262;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(3) .card-content a {
    background-color: #eab03b;
    margin-bottom: auto;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(4) .card-content a {
    background-color: #4b80a9;
    margin: auto;
}
.content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(5) .card-content a {
    background-color: #522d6d;
}


.content.about .section.intro .cards-container .card-slide .card:nth-child(1) .card-content a {
    background-image: linear-gradient(to left, #379144, #379144,transparent, transparent);
    background-size: 300% 100%;
    background-position: 0% 100%;
    transition: all 240ms ease-out;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(1).show .card-content a {
    background-position: 100%;
    transition: border-color 900ms ease-in-out 400ms, background-position 900ms ease-in-out 400ms;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(1).show .card-content a:hover {
    background: transparent;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(2) .card-content a {
    background-image: linear-gradient(to left, #003262, #003262,transparent, transparent);
    background-size: 300% 100%;
    background-position: 0% 100%;
    transition: all 240ms ease-out;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(2).show .card-content a {
    background-position: 100%;
    transition: border-color 900ms ease-in-out 400ms, background-position 900ms ease-in-out 400ms;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(2).show .card-content a:hover {
    background: transparent;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(3) .card-content a {
    background-image: linear-gradient(to left, #eab03b, #eab03b,transparent, transparent);
    background-size: 300% 100%;
    background-position: 0% 100%;
    transition: all 240ms ease-out;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(3).show .card-content a {
    background-position: 100%;
    transition: border-color 900ms ease-in-out 400ms, background-position 900ms ease-in-out 400ms;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(3).show .card-content a:hover {
    background: transparent;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(4) .card-content a {
    background-image: linear-gradient(to left, #4b80a9, #4b80a9,transparent, transparent);
    background-size: 300% 100%;
    background-position: 0% 100%;
    transition: all 240ms ease-out;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(4).show .card-content a {
    background-position: 100%;
    transition: border-color 900ms ease-in-out 400ms, background-position 900ms ease-in-out 400ms;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(4).show .card-content a:hover {
    background: transparent;
}

.content.about .section.intro .cards-container .card-slide .card:nth-child(5) .card-content a {
    background-image: linear-gradient(to left, #522d6d, #522d6d,transparent, transparent);
    background-size: 300% 100%;
    background-position: 0% 100%;
    transition: all 240ms ease-out;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(5).show .card-content a {
    background-position: 100%;
    transition: border-color 900ms ease-in-out 400ms, background-position 900ms ease-in-out 400ms;
}
.content.about .section.intro .cards-container .card-slide .card:nth-child(5).show .card-content a:hover {
    background: transparent;
}



.content.about .section.intro .cards-container .card-controls {
    z-index: 100;
    position: absolute;
    bottom: -42px;
    right: 24px;
    /* opacity: 0; */
    padding: 8px;
    box-sizing: border-box;
    transform: translateY(100%);
    transition: all 200ms ease-out;
}
.content.about .section.intro.show .cards-container .card-controls {
    opacity: 1;
    bottom: 24px;
    transform: translateY(0);
    transition: all 600ms ease-in-out 2.2s;
}
.content.about .section.intro .cards-container .card-controls img {
    z-index: 40;
    position: relative;
    max-width: 72px;
    margin: 0 8px;

    cursor: pointer;
}

.content.about .section.intro .cards-container .card-controls img:active {
    transform: scale(.9);
}

.content.about .section.intro .cards-container .card-color-overlay {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;

    width: 50%;
    height: 50%;

    background-image: linear-gradient(-45deg, rgba(0, 0, 0, .6) 25%, rgba(0, 0, 0, 0) 50%);
}


@media (min-width: 768px) {
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 90%;
    }
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card {
        margin: 16px;
        min-width: 300px;
        max-width: 300px;
        height: 380px;
    }
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(1) .card-content a,
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(2) .card-content a ,
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(3) .card-content a ,
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(4) .card-content a ,
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(5) .card-content a  {
        margin-bottom: 12px;
    }
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content h4 {
        margin-bottom: 8px;
    }
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card:nth-child(1) .card-content img.card-logo {
        margin-top: 42px;
    }
    .content.about .section.intro-mobile .cards-container-mobile .card-slide-mobile .card .card-content p {
        font-size: 16px;
    }
}
/* .content.about .section.intro .cards-container .card-controls img.control-bg {
    z-index: 30;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 280px;
    margin: 0;
} */
@media (min-width: 1280px) {
    .content.about .section.intro {
        display: flex;
    }
    .content.about .section.intro-mobile {
        display: none;
    }

    .content.about .section.intro .text-content {
        min-width: 600px;
        max-width: 600px;
        background-image: linear-gradient(to right, #fff 60%, rgba(250, 250, 250, .75), transparent);
    }
    .content.about .section.intro .text-content > div {
        margin-left: 10%;
        /* max-width: 70%; */
    }
    .content.about .section.intro .text-content > div h2 {
        font-size: 32px;
        margin-bottom: 12px;
        max-width: none;
    }
    .content.about .section.intro .text-content > div p {
        font-size: 18px;
    }
}
@media (min-width: 1600px) {
    .content.about .section.intro .text-content {
        min-width: 800px;
        max-width: 800px;
    }
    .content.about .section.intro .text-content > div h2 {
        font-size: 36px;
    }
}
@media (min-width: 1900px) {
    .content.about .section.intro .text-content {
        min-width: 900px;
        max-width: 900px;
    }
    .content.about .section.intro .text-content > div {
        margin-left: 15%;
    }
    .content.about .section.intro .text-content > div h2 {
        font-size: 42px;
    }
    .content.about .section.intro .text-content > div p {
        font-size: 20px;
    }
}