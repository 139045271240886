.content.about .section.who-we-are {
    position: relative;
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/textured-bg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    padding: 32px 0;
}

.content.about .section.who-we-are h2,
.content.about .section.who-we-are p {
    margin: 0;
}

.content.about .section.who-we-are .vision,
.content.about .section.who-we-are .mission,
.content.about .section.who-we-are .values {
    width: 100%;
    max-width: 80%;
    margin: auto;
    margin-bottom: 42px;
}

.content.about .section.who-we-are .vision h2,
.content.about .section.who-we-are .mission h2 {
    font-family: 'Agatho';
    font-size: 42px;
    font-weight: 500;
    color: #404041;
    line-height: 1em;
    margin-bottom: 4px;
}
.content.about .section.who-we-are .vision h2 span,
.content.about .section.who-we-are .mission h2 span {
    font-family: 'Agatho';
    font-weight: 600;
    color: #379144;
    margin-left: -8px;
}
.content.about .section.who-we-are .vision small,
.content.about .section.who-we-are .mission small {
    display: block;
    font-size: 16px;
    font-weight: 500;
    /* color: #404041; */
    opacity: .9;
    line-height: 1em;
    /* margin-top: -4px;
    margin-bottom: 12px; */
    margin-top: 5px;
    margin-bottom: 30px;
}
.content.about .section.who-we-are .mission p {
    font-size: 14px;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 24px;
    /* color: #404041; */
}

.content.about .section.who-we-are .vision p {
    font-size: 14px;
    color: #404041;
    text-align: justify;
    text-align-last: left;
}
.content.about .section.who-we-are .vision p em {
    font-family: 'Agatho';
    font-style: normal;
    font-size: 1.15em;
    font-weight: bolder;
    letter-spacing: .025em;
    color: #379144;
}

.content.about .section.who-we-are .values {
    margin-top: 42px;
    max-width: 1000px;
}
.content.about .section.who-we-are .values h2 {
    font-family: 'Agatho';
    font-size: 36px;
    word-spacing: -.15em;
    text-align: left;
    color: #404041;
    margin-bottom: 24px;
}
.content.about .section.who-we-are .values h2 span {
    font-family: 'Agatho';
    color: #379144;
}

.content.about .section.who-we-are .values .values-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.content.about .section.who-we-are .values .values-cont > div:nth-child(2) {
    margin-top: 12px;
}
.content.about .section.who-we-are .values .values-cont > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 42px;
    margin-bottom: 42px;
    width: 100%;

}
.content.about .section.who-we-are .values .values-cont > div h4 { 
    font-size: 24px;
    text-align: center;
    color: #379144;
    margin: 12px 0;
}
.content.about .section.who-we-are .values .values-cont  > div ul { 
    padding-inline-start: 0;
    margin: 0 auto;
    max-width: 90%;
}
.content.about .section.who-we-are .values .values-cont  > div ul li {
    text-align: center;
}
.content.about .section.who-we-are .values .values-cont > div img {
    align-self: flex-start;
    width: auto;
    max-width: 100px;

    margin: 0 auto;
    transform: translateY(24px);
    opacity: 0;
    transition: all 400ms ease-out;
}

.content.about .section.who-we-are .values .values-cont > div.show img {
    transform: translateY(0);
    opacity: 1;
    transition: all 600ms ease-in-out 200ms;
}

.content.about .section.who-we-are .values .values-cont > div:nth-child(5) img {
    transform: translateX(-16px) translateY(24px);
}

.content.about .section.who-we-are .values .values-cont > div.show:nth-child(5) img {
    transform: translateX(-16px) translateY(0);
}

@media (min-width: 768px) {
    .content.about .section.who-we-are .values h2 {
        font-size: 42px;
    }
    .content.about .section.who-we-are .values .values-cont {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .content.about .section.who-we-are .values .values-cont > div {
        max-width: 280px;
        margin: 28px 12px;
    }
    .content.about .section.who-we-are .vision p, 
    .content.about .section.who-we-are .mission p {
        font-size: 16px;
    }
}
@media (min-width: 1440px) {
    .content.about .section.who-we-are .values .values-cont {
        max-width: 1200px;
        margin: 0 auto;
    }
}
@media (min-width: 1600px) {
    .content.about .section.who-we-are .vision, .content.about .section.who-we-are .mission {
        max-width: 1000px;
    }
    .content.about .section.who-we-are {
        padding: 60px 0;
    }
}
@media (min-width: 1900px) {
    .content.about .section.who-we-are .vision h2, .content.about .section.who-we-are .mission h2 {
        font-size: 64px;
    }
    .content.about .section.who-we-are .values h2 {
        font-size: 64px;
    }
}